import axios from 'axios';
import pjson from '../../package.json';
import { API_BASE_URL } from '../environment';
import { getToken, logout } from '../helpers/storage';

import endpoints from './endpoints';

const defaultParams = {
};

const defaultConfig = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: API_BASE_URL,
};

function ApiClient(headerName = 'Bearer', addToken = true) {
  this.instance = axios.create(defaultConfig);

  if (addToken) {
    this.instance.interceptors.request.use((config) => {
      const newConfig = {
        ...config,
      };
      try {
        const storedPosition = JSON.parse(localStorage.getItem('__position'));
        if (!storedPosition.error && storedPosition.latitude && storedPosition.longitude) {
          const position = `${storedPosition.latitude},${storedPosition.longitude}`;
          newConfig.headers.common['X-Position'] = position;
        }
      } catch (e) {
        console.error('Error getting position on request interceptor');
      }
      const token = getToken();
      if (token) {
        newConfig.headers.common.Authorization = `${headerName} ${getToken()}`;
      }
      return newConfig;
    });
  }
  this.instance.interceptors.response.use(
    (response) => {
      const minRentalVersion = '0.0.1'; // response.headers['x-min-p-v'];
      if (minRentalVersion && pjson.version < minRentalVersion) {
        window.location.reload();
      }
      return response;
    },
    (error) => {
      let noRedir = false;
      try {
        noRedir = JSON.parse(error?.config?.data).noRedir;
        // eslint-disable-next-line no-empty
      } catch (e) { }
      if (error
        && error.response
        && error.response.status
        && error.response.status === 401
        && !noRedir
      ) {
        logout();
        if (window.location.pathname !== '/login') {
          window.location = '/login';
          return null;
        }
      }

      return Promise.reject(error);
    },
  );

  /* this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Error! ", error.response);
        return Promise.reject(error);
      },
  ); */

  Object.keys(endpoints).forEach((endpoint) => {
    this[endpoint] = endpoints[endpoint](this.instance, defaultParams);
  });
}

const apiClient = new ApiClient();
const apiClientJwt = new ApiClient('JWT');
const apiClientNoToken = new ApiClient('', false);

export { apiClient as default, apiClientJwt, apiClientNoToken };
