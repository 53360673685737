/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useState, useEffect } from 'react';
import { useMqttState } from 'mqtt-react-hooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import QRCode from 'react-qr-code';
import isMobile from 'is-mobile';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlug,
  faSync,
  faLocationArrow,
  faQrcode,
  faExternalLinkAlt,
  faCopy,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
  Button, Popconfirm,
} from 'antd';

import MqttSubscriptionList from '../../MqttSubscriptionList';
import EventsList from '../../EventsList';
import MqttLogList from '../../MqttLogList';
import MapPositionsList from '../../MapPositionsList';
import CopyWithMessage from '../../CopyWithMessage';

import styles from './index.module.scss';

import {
  getBatteryIcon,
} from '../../../helpers/params';
import {
  getLog,
  getEvents,
  getGeopositions,
  getDeviceByTypeQROrRef,
  deleteByTypeRef,
} from '../../../services/devices';
import { MapComponent } from '../../MapComponent';

const reportModes = {
  '-1': 'Silencioso',
  0: 'Manual',
  1: 'Cambio significante',
  2: 'Movimiento',
};

const InternalTransportResume = ({
  user,
  defaultDevice,
  showClose,
  onClose,
  showMap,
  openActions,
  updating,
  buttonCols,
  pollPeriod,
}) => {
  const [showActions, setShowActions] = useState(openActions);
  const [loadingLog, setLoadingLog] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingGeopositions, setLoadingGeopositions] = useState(false);

  const { client } = useMqttState();
  const [log, setLog] = useState(false);
  const [events, setEvents] = useState(false);
  const [device, setDevice] = useState(defaultDevice);

  const [geopositions, setGeopositions] = useState(false);

  const loadLog = async (hours = 24) => {
    setLoadingLog(true);
    const rsp = await getLog(device.ref, device.type, hours);
    if (rsp && rsp.data) {
      setLog([...rsp.data, ...(log || [])]);
    }
    setLoadingLog(false);
  };

  const loadEvents = async (hours = 168) => {
    setLoadingEvents(true);
    const rsp = await getEvents(device.ref, device.type, hours);
    if (rsp && rsp.data) {
      setEvents([...rsp.data, ...(events || [])]);
    }
    setLoadingEvents(false);
  };
  const loadGeopositions = async () => {
    setLoadingGeopositions(true);
    const rsp = await getGeopositions(device.ref, device.type, 48);
    if (rsp && rsp.data) {
      setGeopositions(rsp.data);
    }
    setLoadingGeopositions(false);
  };

  const updateDevice = async () => {
    if (!device) {
      return;
    }
    const rsp = await getDeviceByTypeQROrRef({
      type: device.type,
      code: device.ref,
    });
    if (rsp && rsp.status) {
      setDevice(rsp.data);
    }
  };

  const onDeleteByTypeRef = async () => {
    const rsp = await deleteByTypeRef(
      device.ref,
      device.type,
    );
    if (rsp && rsp.status) {
      setDevice(null);
    }
  };

  useEffect(() => { setShowActions(openActions); }, [openActions]);
  useEffect(() => { setDevice(defaultDevice); }, [defaultDevice]);

  useEffect(
    () => {
      // onAction('get_lock_status', null, false);
      if (!device) {
        return null;
      }
      const pid = window.setInterval(updateDevice, pollPeriod);
      return () => {
        if (pid) {
          window.clearInterval(pid);
        }
      };
    }, [device],
  );
  if (!device) {
    return null;
  }

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCIi-49zQc_qm-8TCRX26suYgt0oL1YETw&scale=2&center=${device.lat},${device.lng}&zoom=16&size=350x350&maptype=roadmap&markers=color:green%7Clabel:G%7C${device.lat},${device.lng}`;

  return (
    <>
      <div className={styles.device}>
        <div className={styles.title}>
          <div className={styles.qr}>
            {
              user.can.manage_devices ? (
                <>
                  <FontAwesomeIcon icon={faQrcode} />
                  {' '}
                  <Link to={`/device/${device.ref}`}>{device.qr}</Link>
                  {' '}
                  <Link to={`/device/${device.ref}`}>{device.ref}</Link>
                </>
              )
                : (
                  <>
                    {device.qr}
                    {' '}
                    -
                    {' '}
                    {device.ref}
                  </>
                )
            }
          </div>

          <div className={styles.battery}>
            <div className={styles.info}>
              <FontAwesomeIcon icon={getBatteryIcon(device.data.batt)} style={{ fontSize: 18 }} />
              {' '}
              {device.data.bs === '2' ? <FontAwesomeIcon icon={faPlug} style={{ fontSize: 12 }} /> : null}
              {' '}
              {device.data.batt}
            </div>
            <div className={styles.legend}>Batería Teléfono</div>
          </div>
          {showClose && (
            <div className={styles.closeBtn} onClick={onClose} role="button">
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} />
            </div>
          )}

        </div>
        <div className={styles.tools}>
          {user.can.delete_devices
            && (
              <Popconfirm
                placement="bottom"
                title="¿Confirmas que quieres eliminar este patín?"
                onConfirm={onDeleteByTypeRef}
                okText="Si"
                okButtonProps={{
                  type: 'danger',
                }}
                cancelText="No"
              >
                <Button
                  size="small"
                  type="danger"
                >
                  Eliminar patín
                </Button>
              </Popconfirm>
            )}
        </div>
        <div className={styles.info}>
          {device.data?.coderr > 0 && (
            <div style={{
              display: 'inline-block',
              background: '#f33',
              color: '#fff',
              fontWeight: 'bold',
              padding: '5px 15px',
              borderRadius: 20,
              fontSize: 14,
              marginBottom: 40,
            }}
            >
              Error
              {' '}
              {device.data?.coderr}
            </div>
          )}
          <table className={styles.table}>

            <tr>
              <td width="25%">Id, QR, Ref</td>
              <td>
                <CopyWithMessage
                  inline
                  value={device.id}
                  messageCopied="Copiado!"
                  messageTime={2000}
                >
                  {device.id}
                  {' '}

                  <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                </CopyWithMessage>
                ,
                {' '}
                <CopyWithMessage
                  inline
                  value={device.qr}
                  messageCopied="Copiado!"
                  messageTime={2000}
                >
                  {device.qr}
                  {' '}

                  <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                </CopyWithMessage>
                ,
                {' '}
                <CopyWithMessage
                  inline
                  value={device.ref}
                  messageCopied="Copiado!"
                  messageTime={2000}
                >
                  {device.ref}
                  {' '}

                  <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                </CopyWithMessage>
                {device.data?.owner && (
                  <div>{device.data.owner.name}</div>
                )}
              </td>
            </tr>
            {user.can.manage_devices
              ? (
                <>
                  <tr>
                    <td>Link</td>
                    <td>
                      <Link to={`/device/${device.ref}`}>{`/device/${device.ref}`}</Link>
                      <div style={{ display: 'inline-block', marginLeft: 20 }}>
                        <CopyWithMessage
                          value={`https://rental.grow.mobi/device/${device.ref}`}
                          messageCopied="Copiado!"
                          messageTime={2000}
                        >
                          <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                        </CopyWithMessage>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Modo de actualización de ubicación</td>
                    <td>
                      {reportModes[device.data.m]}
                      {' '}
                      {device.data.m !== 2 && (
                        <Button
                          onClick={() => {
                            client.publish(
                              `owntracks/growapi/ud${device.qr}/cmd`,
                              JSON.stringify({
                                _type: 'cmd',
                                action: 'setConfiguration',
                                configuration: {
                                  _type: 'configuration',
                                  monitoring: 2,
                                },
                              }),
                            );
                            client.publish(
                              `owntracks/growapi/ud${device.qr}/cmd`,
                              JSON.stringify({
                                _type: 'cmd',
                                action: 'reportLocation',
                              }),
                            );
                          }}
                          type="secondary"
                          size="small"
                        >
                          {updating && <FontAwesomeIcon icon={faSync} spin />}
                          Cambiar a Movimiento
                        </Button>
                      )}
                      {device.data.m !== 1 && (
                        <Button
                          onClick={() => {
                            client.publish(
                              `owntracks/growapi/ud${device.qr}/cmd`,
                              JSON.stringify({
                                _type: 'cmd',
                                action: 'setConfiguration',
                                configuration: {
                                  _type: 'configuration',
                                  monitoring: 1,
                                },
                              }),
                            );
                            client.publish(
                              `owntracks/growapi/ud${device.qr}/cmd`,
                              JSON.stringify({
                                _type: 'cmd',
                                action: 'reportLocation',
                              }),
                            );
                          }}
                          type="secondary"
                          size="small"
                        >
                          {updating && <FontAwesomeIcon icon={faSync} spin />}
                          Cambiar a Cambio significante
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Ubicación</td>
                    <td>
                      <a href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`} target="_map">
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                        {' '}
                        Ver en mapa
                      </a>
                      <div style={{ display: 'inline-block', marginLeft: 20 }}>
                        <CopyWithMessage
                          value={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`}
                          messageCopied="Copiado!"
                          messageTime={2000}
                        >
                          <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
                        </CopyWithMessage>
                      </div>
                      {device.data?.out_of_area ? 'FUERA DE AREA' : ''}
                    </td>
                  </tr>
                </>
              )
              : (
                <tr>
                  <td>Ubicación</td>
                  <td>
                    <a href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`} target="_map">
                      <img
                        style={{ maxWidth: '100%', width: 400 }}
                        src={mapUrl}
                        alt="Map"
                      />
                      <div style={{ textAlign: 'center', marginTop: 10 }}>

                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                        {' '}
                        Ver en mapa
                      </div>
                    </a>
                  </td>
                </tr>
              )}

            <tr>
              <td>Flota</td>
              <td>{device.fleet?.name || 'Sin Flota'}</td>
            </tr>

          </table>

          <div className={styles.actions}>
            {showActions
              ? (
                <>
                  <div className={styles.commands} style={{ marginTop: 20, gridTemplateColumns: `repeat(${isMobile() ? 2 : buttonCols}, 1fr)` }}>
                    <Button
                      onClick={() => client.publish(
                        `owntracks/growapi/ud${device.qr}/cmd`,
                        JSON.stringify({
                          _type: 'cmd',
                          action: 'reportLocation',
                        }),
                      )}
                      type="secondary"
                    >
                      {updating && <FontAwesomeIcon icon={faSync} spin />}
                      <FontAwesomeIcon icon={faLocationArrow} />
                      Act geolocalización
                    </Button>
                  </div>

                </>
              )
              : (
                <Button
                  onClick={() => setShowActions(true)}
                  type="secondary"
                >
                  Mostrar acciones
                </Button>
              )}
          </div>
        </div>

        {showMap
          && (
            <div className={styles.map} style={{ width: '100%', maxWidth: 500, marginTop: 20 }}>
              <MapComponent
                key={device.id}
                fleetId={device.fleet_id}
                zoom={16}
                defaultCenter={{ lat: device.lat, lng: device.lng }}
                minZoom={1}
                maxZoom={20}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                containerElement={(
                  <div
                    style={{
                      width: isMobile() ? '100%' : 500,
                      height: isMobile() ? 300 : 400,
                    }}
                  />
                )}
                mapElement={<div style={{ borderRadius: 5, width: '100%', height: '100%' }} />}
                markers={[device]}
              />
              <a href={`https://www.google.com/maps/search/?api=1&query=${device.lat},${device.lng}`} target="_map">
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                  {' '}
                  Ver en mapa
                </div>
              </a>
              <div className={styles.coordinates}>
                <CopyWithMessage
                  inline
                  value={`${device.lat}, ${device.lng}`}
                  messageCopied="Copiado!"
                  messageTime={2000}
                >
                  {device.lat}
                  {', '}
                  {device.lng}
                </CopyWithMessage>
              </div>
              <small>
                {moment.utc(device.geo_updated_at).fromNow()}
              </small>

            </div>
          )}

        {user.can.devices_advanced_ops && (
          <>
            <div className={styles.events}>
              <div className={styles.sectionTitle}>
                Eventos
                {' '}
                {device.qr}
                {' '}
                (últ 7 días)
                {events
                  && (
                    <span onClick={() => loadEvents(1)} style={{ marginLeft: 10 }}>
                      <FontAwesomeIcon
                        icon={faSync}
                        spin={loadingEvents}
                      />
                    </span>
                  )}

              </div>
              <div className={styles.inner}>
                {events
                  ? (
                    <EventsList events={events.map((x) => ({
                      ...x,
                    }))}
                    />
                  )
                  : (
                    <div onClick={() => loadEvents(168)} className={styles.clickLoadArea}>
                      {loadingEvents ? 'Cargando...' : (
                        <a>
                          Cargar eventos
                          {' '}
                        </a>
                      )}
                    </div>
                  )}
              </div>
            </div>
            <div className={styles.log}>
              <div className={styles.sectionTitle}>
                MQTT Log
                {' '}
                {device.qr}
                {' '}
                (últ 24 h)
                {log
                  && (
                    <span onClick={() => loadLog(1)} style={{ marginLeft: 10 }}>
                      <FontAwesomeIcon
                        icon={faSync}
                        spin={loadingLog}
                      />
                    </span>
                  )}
              </div>
              <div className={styles.inner}>
                {log
                  ? (
                    <MqttLogList events={log.map((x) => ({
                      ...x,
                    }))}
                    />
                  )
                  : (
                    <div onClick={() => loadLog(24)} className={styles.clickLoadArea}>
                      {loadingLog ? 'Cargando...' : (
                        <a>
                          Cargar log MQTT
                          {' '}
                        </a>
                      )}
                    </div>
                  )}
              </div>
            </div>
            {!isMobile()
              && (
                <div className={styles.positions}>
                  <div className={styles.sectionTitle}>
                    Posiciones del dispositivo
                    {' '}
                    {device.qr}
                    {' '}
                    (últ 48 horas)
                  </div>
                  <div className={styles.inner}>
                    {geopositions
                      ? (
                        <MapPositionsList
                          device={device}
                          positions={geopositions.map((x) => ({
                            ...x,
                          }))}
                        />
                      )
                      : (
                        <div onClick={loadGeopositions} className={styles.clickLoadArea}>
                          {loadingGeopositions ? 'Cargando...' : (
                            <a>
                              Cargar Posiciones del patín
                              {' '}
                            </a>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )}
          </>
        )}
        {user.can.devices_advanced_ops && (
          <div className={styles.mqtt_commands}>
            <MqttSubscriptionList topic={`s/c/${device.ref}`} height={300} showResend />
            <MqttSubscriptionList topic={`s/a/17/${device.ref}`} height={300} />
            <MqttSubscriptionList topic={`s/h/17/${device.ref}`} height={300} />
          </div>
        )}

      </div>
      <br />
      <br />
      {
        user.can.devices_advanced_ops && (
          <div style={{
            textAlign: 'center', maxWidth: '100%', width: 400, margin: '20px auto 50px',
          }}
          >
            <QRCode value={`https://api.ongrim.com/search/scooter/${device.qr}`} />
            <br />
            {device.qr}
          </div>
        )
      }
    </>
  );
};
InternalTransportResume.defaultProps = {
  onClose: () => { },
  updating: false,
  openActions: true,
  showMap: false,
  showClose: false,
  buttonCols: 8,
  pollPeriod: 5000,
};
InternalTransportResume.propTypes = {
  user: PropTypes.object.isRequired,
  defaultDevice: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  updating: PropTypes.bool,
  openActions: PropTypes.bool,
  showMap: PropTypes.bool,
  showClose: PropTypes.bool,
  buttonCols: PropTypes.number,
  pollPeriod: PropTypes.number,
};
export default InternalTransportResume;
