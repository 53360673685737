import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
} from 'antd';

import { documentTypes } from '../../helpers/params';

const SubscriptionDocumentsTable = ({
  documents,
}) => {
  const columns = [

    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (v) => documentTypes[v] || v,
    },
    {
      title: '',
      dataIndex: 'private_url',
      align: 'center',
      key: 'private_url',
      render: (v, r) => (r.mimetype === 'image/jpeg'
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        ? <a href={v} target="_blank" rel="noreferrer"><img style={{ height: 150 }} src={v} alt="" /></a>
        : null),
    },
    {
      title: 'Link',
      dataIndex: 'private_url',
      align: 'center',
      key: 'private_url',
      render: (v) => <a href={v} target="_blank" rel="noreferrer">{v}</a>,
    }].filter((x) => !!x);

  return (
    <Table
      size="small"
      pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
      locale={{ emptyText: 'Sin suscripciones' }}
      columns={columns}
      dataSource={documents ? documents.map((d) => ({
        key: d.id,
        ...d,
      })) : []}
    />
  );
};

SubscriptionDocumentsTable.defaultProps = {
  documents: [],
};
SubscriptionDocumentsTable.propTypes = {
  documents: PropTypes.array,
};

export default SubscriptionDocumentsTable;
