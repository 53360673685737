/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import { pointNamesByType, subscriptionStatusColor } from '../../helpers/params';

const SubscriptionResume = ({
  subscription,
  actions,
  children,
}) => (
  <div className={styles.subscription}>
    <div className={styles.info}>
      <div className={styles.plan}>

        #
        {subscription.id}
        {' '}
        -
        {' '}
        <strong>
          {subscription.item_type}
        </strong>
        . Cantidad:
        {' '}
        <strong>
          <span style={{ marginRight: 10 }}>
            {subscription.qty}
          </span>
          {subscription.items?.filter((x) => x.status === 'ASSIGNED').map((item) => (
            <span
              style={{ marginLeft: 10 }}
            >
              QR:
              {' '}
              {item.item?.qr}
            </span>
          ))}
        </strong>

      </div>
      <div>
        <Badge
          count={subscription.status_tr}
          showZero
          color={subscriptionStatusColor[subscription.status]}
        />
      </div>
      <div className={styles.user}>
        <FontAwesomeIcon icon={faUser} style={{ marginRight: 5 }} />
        {subscription.user.name}

        <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5, marginLeft: 15 }} />
        {subscription.user.phone}
      </div>
      <div className={styles.address}>
        {pointNamesByType[subscription.data?.shipping_point]}
        {' > '}
        {subscription.user?.data?.grin4uinfo
          // eslint-disable-next-line prefer-template
          ? `${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_address']}, ${subscription.user?.data?.grin4uinfo[subscription.data?.shipping_point + '_district']}`
          : '-'}
      </div>

    </div>
    <div className={styles.actions}>
      {actions}
    </div>
    {children}
  </div>
);

SubscriptionResume.defaultProps = {
  actions: null,
  children: null,
};

SubscriptionResume.propTypes = {
  subscription: PropTypes.object.isRequired,
  actions: PropTypes.any,
  children: PropTypes.any,
};

export default SubscriptionResume;
