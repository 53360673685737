/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const getMe = async () => executeAndWaitResponse(() => apiClient.users.me());
export const getById = async (id, includeEvents = false) => executeAndWaitResponse(() => apiClient.users.get(id, includeEvents));
export const getByHash = async (hash) => executeAndWaitResponse(() => apiClient.users.getByHash(hash));
export const getByEmail = async (email) => executeAndWaitResponse(() => apiClient.users.getByEmail(email));
export const register = async (payload) => executeAndWaitResponse(() => apiClient.users.register(payload));
export const login = async (payload) => executeAndWaitResponse(() => apiClient.users.login(payload));
export const findUsers = async (payload) => executeAndWaitResponse(() => apiClient.users.find(payload));
export const findUsersAdvanced = async (payload) => executeAndWaitResponse(() => apiClient.users.findUsersAdvanced(payload));
export const assignPromocodeToUser = async (userId, promocodeId) => executeAndWaitResponse(() => apiClient.users.assignPromocodeToUser(userId, promocodeId));
export const createPromocodeForUser = async (payload) => executeAndWaitResponse(() => apiClient.users.createPromocodeForUser(payload));
export const updateGrin4UInfo = async (id, payload) => executeAndWaitResponse(() => apiClient.users.updateGrin4UInfo(id, payload));
export const setUserStatus = async (id, status) => executeAndWaitResponse(() => apiClient.users.setStatus(id, status));
export const setCardStatus = async (cardId, status) => executeAndWaitResponse(() => apiClient.users.setCardStatus(cardId, status));
export const addRole = async (id, role) => executeAndWaitResponse(() => apiClient.users.addRole(id, role));
export const removeRole = async (id, role) => executeAndWaitResponse(() => apiClient.users.removeRole(id, role));
export const addFleet = async (id, role) => executeAndWaitResponse(() => apiClient.users.addFleet(id, role));
export const removeFleet = async (id, role) => executeAndWaitResponse(() => apiClient.users.removeFleet(id, role));
export const modifyPassword = async (payload) => executeAndWaitResponse(() => apiClient.users.modifyPassword(payload));
export const updatePaymentMultiplier = async (id, value) => executeAndWaitResponse(() => apiClient.users.updatePaymentMultiplier(id, value));
export const updatePhone = async (id, value) => executeAndWaitResponse(() => apiClient.users.updatePhone(id, value));
export const updateName = async (id, value) => executeAndWaitResponse(() => apiClient.users.updateName(id, value));
export const updateDni = async (id, value) => executeAndWaitResponse(() => apiClient.users.updateDni(id, value));
export const updateContract = async (id, contract) => executeAndWaitResponse(() => apiClient.users.updateContract(id, contract));
export const updatePhoto = async (id, file) => executeAndWaitResponse(() => apiClient.users.updatePhoto(id, file));
export const updateCountry = async (id, value) => executeAndWaitResponse(() => apiClient.users.updateCountry(id, value));
export const changeWorkingStatus = async (id, value) => executeAndWaitResponse(() => apiClient.users.changeWorkingStatus(id, value));
export const updateCustomerDocument = async (id, documentId, file) => executeAndWaitResponse(() => apiClient.users.updateCustomerDocument(id, documentId, file));
export const setReferralCode = async (id, referralCode) => executeAndWaitResponse(() => apiClient.users.setReferralCode(id, referralCode));

export default {
  getMe,
  getById,
  getByHash,
  register,
  addRole,
  changeWorkingStatus,
  removeRole,
  addFleet,
  findUsersAdvanced,
  removeFleet,
  createPromocodeForUser,
  findUsers,
  setUserStatus,
  setCardStatus,
  modifyPassword,
  updateGrin4UInfo,
  assignPromocodeToUser,
  updatePaymentMultiplier,
  updatePhone,
  updateDni,
  updateName,
  setReferralCode,
  updateCountry,
  updateCustomerDocument,
};
