/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const createEvent = async (payload) => executeAndWaitResponse(() => apiClient.events.create(payload));
export const getForItem = async (itemType, itemId) => executeAndWaitResponse(() => apiClient.events.getForItem(itemType, itemId));

export default {
  createEvent,
  getForItem,
};
