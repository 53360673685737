/* eslint-disable react/prop-types */
import { useEffect } from 'react';

import Centrifuge from 'centrifuge';

const CentrifugoSubscribeChannel = ({
  websocket,
  token,
  channel,
  onConnect,
  onDisconnect,
  onError,
  onMessage,
}) => {
  useEffect(async () => {
    // Create a client instance
    // const client = new Client({ uri: 'wss://broker.grow.mobi:9001/ws', clientId: 'clientId', storage: myStorage });

    const centrifuge = new Centrifuge(websocket);
    centrifuge.setToken(token);
    centrifuge.subscribe(channel, (message) => {
      if (onMessage) { onMessage(channel, message); }
    });
    centrifuge.on('connect', (context) => {
      if (onConnect) { onConnect(channel, context); }
    });
    centrifuge.on('disconnect', (context) => {
      if (onDisconnect) { onDisconnect(channel, context); }
    });
    centrifuge.on('error', (error) => {
      if (onError) { onError(channel, error); }
    });
    console.info('CENTRIFUGO CONNECTING');
    centrifuge.connect();

    return () => {
      centrifuge.disconnect();
    };
  }, []);
  return null;
};

export default CentrifugoSubscribeChannel;
