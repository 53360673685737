/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import stylesLayout from '../../common/layout.module.scss';
import useHorusQuery from '../../hooks/useHorusQuery';

const AnalysisLeadsModule = () => {
  const {load, status, data} = useHorusQuery();

  useEffect(() => {
    const query = `select period, min(d), max(d), type, \\\`group\\\` as group_name, sum(if(status='Concretado', n, 0)) concretado, sum(n) total, sum(if(status='Concretado', n, 0))/sum(n) pct_conversión  from (
select date_format(lead_date, '%Y-%m-%d') period,  date_format(lead_date, '%Y-%m-%d') d, json_unquote(json_extract(l.data, '$.origen')) origen, ct.type,ct.group, status, count(*) n from grin4u_leads l left join grin4u_campaign_types ct on ct.campaign=json_unquote(json_extract(l.data, '$.origen'))  
where lead_date>='2024-05-01'
 group by 1, 2,3,4,5,6 having period is not null
) tmp group by 1, 4,5 having group_name='AD META';
`;
    load(query);
  }, []);

  console.log('status', status);
  console.log('data', data);

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>Análisis Leads Grin4U</div>
      <div className={stylesLayout.content}>
        <div>Hola</div>
      </div>
    </div>
  );
};
export default AnalysisLeadsModule;
