/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Table, Badge } from 'antd';

import numeral from 'numeral';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import * as horusService from '../../services/horus';

import Loading from '../../components/Loading';

import stylesLayout from '../../common/layout.module.scss';
import {
  RenderLineChart, RenderBarChart, avgLast, accumulatedArray,
} from '../../components/Charts';
import Metric from '../../components/Metric';
import DeltaPercentageBadge from '../../components/DeltaPercentBadge';
import { subscriptionStatusColor } from '../../helpers/params';

dayjs.extend(utc);

const DashboardModule = ({
  selectedFleet,
}) => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState(false);

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };
  const loadAll = async () => {
    setStatus('loading');
    const queryDailyResume = `
select day, count(distinct if(is_first, user_id, null)) new_customers, sum(if(is_first, months*qty, 0)) new_memberships,
sum(if(is_first, total, 0)) new_amount,
count(distinct if(not is_first, user_id, null)) recurring_customers, sum(if(not is_first, months*qty, 0)) recurring_memberships,
sum(if(not is_first, total, 0)) recurring_amount from (
select date_format(paid_at+ INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour, '%Y-%m-%d') day, is_first, s.user_id user_id, count(*) n, s.qty, s.item_type, s.total, 
json_extract(s.data, '$.plan.days')/30 months from renewals r, subscriptions s, fleets f where f.id=s.fleet_id
and s.id=r.subscription_id and r.paid_at is not null
and s.status not in ('DELETED')
and paid_at>='2023-08-01'-INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour
and fleet_id=${selectedFleet.id}
 group by s.id
) tmp group by 1;
 
  `;
    const queryMonthlyResume = `
select month, count(distinct if(is_first, user_id, null)) new_customers, sum(if(is_first, months*qty, 0)) new_memberships,
sum(if(is_first, total, 0)) new_amount,
count(distinct if(not is_first, user_id, null)) recurring_customers, sum(if(not is_first, months*qty, 0)) recurring_memberships,
sum(if(not is_first, total, 0)) recurring_amount from (
select date_format(paid_at+ INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour, '%Y-%m') month, is_first, s.user_id user_id, count(*) n, s.qty, s.item_type, s.total, json_extract(s.data, '$.plan.days')/30 months from renewals r, subscriptions s, fleets f where f.id=s.fleet_id
and s.id=r.subscription_id and r.paid_at is not null
and s.status not in ('DELETED')
and paid_at>='2023-08-01'-INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour
and fleet_id=${selectedFleet.id}
 group by s.id
) tmp group by 1;
 
  `;
    const queryTypeResume = `
select item_type, count(distinct if(is_first, user_id, null)) new_customers, sum(if(is_first, months*qty, 0)) new_memberships,
sum(if(is_first, total, 0)) new_amount,
count(distinct if(not is_first, user_id, null)) recurring_customers, sum(if(not is_first, months*qty, 0)) recurring_memberships,
sum(if(not is_first, total, 0)) recurring_amount from (
select date_format(paid_at+ INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour, '%Y-%m-%d') day, is_first, s.user_id user_id, count(*) n, s.qty, s.item_type, s.total, json_extract(s.data, '$.plan.days')/30 months from renewals r, subscriptions s, fleets f where f.id=s.fleet_id
and s.id=r.subscription_id and r.paid_at is not null
and s.status not in ('DELETED')
and paid_at>='2023-08-01'-INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour
and fleet_id=${selectedFleet.id}
 group by s.id
) tmp group by 1;
 
  `;
    const queryAllRenewals = `
  select date_format(paid_at+ INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour, '%Y-%m-%d') day, s.id subscription_id, r.id renewal_id, if(is_first, 'Nuevo', 'Renovación') new_reneval, s.user_id user_id, u.name user_name, u.email user_email, u.phone user_phone, s.qty, s.item_type, s.total, 
  json_unquote(json_extract(s.data, '$.plan.name')) plan,
  json_extract(s.data, '$.plan.days')/30 months, s.status, s.last_paid_at from renewals r, subscriptions s, users u, fleets f where u.id=s.user_id and f.id=s.fleet_id
  and s.id=r.subscription_id and r.paid_at is not null
  and s.status not in ('DELETED')
  and paid_at>='2023-08-01'-INTERVAL json_unquote(json_extract(f.cost, '$.tz')) hour
  and fleet_id=${selectedFleet.id} group by r.id order by day, s.id;
  `;
    const [rspDailyResume, rspMonthlyResume, rspTypeResume, rspAllRenewals] = await Promise.all([
      horusService.executeQuery(queryDailyResume),
      horusService.executeQuery(queryMonthlyResume),
      horusService.executeQuery(queryTypeResume),
      horusService.executeQuery(queryAllRenewals),
    ]);

    if (rspDailyResume?.status) {
      updateData('dailyResume', rspDailyResume.data);
    } else {
      updateData('dailyResume', []);
    }

    if (rspMonthlyResume?.status) {
      updateData('monthlyResume', rspMonthlyResume.data);
    } else {
      updateData('monthlyResume', []);
    }
    if (rspTypeResume?.status) {
      updateData('typeResume', rspTypeResume.data);
    } else {
      updateData('typeResume', []);
    }
    if (rspAllRenewals?.status) {
      updateData('allRenewals', rspAllRenewals.data);
    } else {
      updateData('allRenewals', []);
    }
    setStatus('loaded');
  };

  useEffect(() => {
    loadAll();
  }, []);

  useEffect(() => {
    loadAll();
  }, [selectedFleet?.id]);

  if (status === 'loading') {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  const typeResumeColumns = [
    {
      title: 'Tipo',
      dataIndex: 'item_type',
      key: 'item_type',
      width: 100,
      defaultSortOrder: 'descend',
      render: (v) => (
        <>
          {v}
        </>
      ),
      sorter: (a, b) => (a.item_type < b.item_type ? -1 : 1),
    },

    {
      title: 'Nuevos Usuarios',
      dataIndex: 'new_customers',
      key: 'new_customers',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    }, {
      title: 'Nuevos Membresias',
      dataIndex: 'new_memberships',
      key: 'new_memberships',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    },

    {
      title: 'Monto pagado por nuevos usuarios',
      dataIndex: 'new_amount',
      key: 'new_amount',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },

  ];

  const dailyResumeColumns = [
    {
      title: 'Día',
      dataIndex: 'day',
      key: 'day',
      width: 100,
      defaultSortOrder: 'descend',
      render: (v, r) => (
        <>
          {dayjs(v).utc().format('YYYY-MM-DD')}
        </>
      ),
      sorter: (a, b) => (a.day < b.day ? -1 : 1),
    },

    {
      title: 'Nuevos Usuarios',
      dataIndex: 'new_customers',
      key: 'new_customers',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    }, {
      title: 'Nuevos Membresias',
      dataIndex: 'new_memberships',
      key: 'new_memberships',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    },

    {
      title: 'Monto pagado por nuevos usuarios',
      dataIndex: 'new_amount',
      key: 'new_amount',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },

  ];

  const monthlyResumeColumns = [
    {
      title: 'Mes',
      dataIndex: 'month',
      key: 'month',
      width: 100,
      defaultSortOrder: 'descend',
      render: (v, r) => (
        <>
          {dayjs(v).utc().format('YYYY-MM')}
        </>
      ),
      sorter: (a, b) => (a.month < b.month ? -1 : 1),
    },

    {
      title: 'Nuevos Usuarios',
      dataIndex: 'new_customers',
      key: 'new_customers',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    }, {
      title: 'Nuevos Membresias',
      dataIndex: 'new_memberships',
      key: 'new_memberships',
      align: 'right',
      render: (v) => (
        <>
          {v}
        </>
      ),
    },

    {
      title: 'Monto pagado por nuevos usuarios',
      dataIndex: 'new_amount',
      key: 'new_amount',
      align: 'right',
      render: (v) => numeral(v).format('0,000'),
    },

  ];

  const allRenewalsColumns = [
    {
      title: 'Día',
      dataIndex: 'day',
      key: 'day',
      width: 100,
      defaultSortOrder: 'descend',
      render: (v, r) => (
        <>
          {dayjs(v).utc().format('YYYY-MM-DD')}
        </>
      ),
      sorter: (a, b) => (a.day < b.day ? -1 : 1),
    },
    {
      title: 'Cliente',
      dataIndex: 'email',
      key: 'email',
      render: (v, r) => (
        <>
          <Link to={`/cliente/${r.user_id}`}>{r.user_name}</Link>
          <div style={{ textDecoration: 'none', color: '#666', fontSize: 12 }}>
            <a
              href={`http://wa.me/${r.user_phone.trim().replaceAll('+', '')}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#666' }}
            >
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#666', fontSize: 12, marginRight: 0 }} />
              {r.user_phone}
            </a>
          </div>
        </>
      ),
    },
    {
      title: 'Suscripción',
      dataIndex: 'subscription_id',
      key: 'subscription_id',
      align: 'center',
      render: (v) => (
        <Link to={`/suscripcion/${v}`}>
          {v}
        </Link>
      ),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (v, r) => (
        <Link to={`/suscripcion/${r.subscription_id}`}>
          {v}
        </Link>
      ),
    },
    {
      title: 'Tipo',
      align: 'center',
      dataIndex: 'item_type',
      key: 'item_type',
    },
    {
      title: 'Cantidad de patines',
      align: 'center',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Meses',
      align: 'center',
      dataIndex: 'months',
      key: 'months',
    },

    {
      title: 'Total',
      dataIndex: 'total',
      align: 'right',
      key: 'total',
      render: (v, r) => (
        <>
          {numeral(v).format(selectedFleet.cost?.currency_format)}
        </>
      ),
    },
  ];

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div style={{ float: 'right' }}>
          <Button onClick={loadAll} size="large">
            <FontAwesomeIcon icon={faSyncAlt} spin={status === 'loading'} />
          </Button>
        </div>
        Reporte - Ventas Grin4U
        <br />
        <small>{selectedFleet.name}</small>
        <div className={stylesLayout.subtitle}>
          * Moneda y hora local
        </div>
      </div>
      <div className={stylesLayout.content}>
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 30 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={monthlyResumeColumns}
          dataSource={data?.monthlyResume?.map((x) => ({
            key: x.month,
            ...x,
          }))}
        />
        <br />
        <br />
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 30 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={typeResumeColumns}
          dataSource={data?.typeResume?.map((x) => ({
            key: x.item_type,
            ...x,
          }))}
        />
        <br />
        <br />
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 30 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={dailyResumeColumns}
          dataSource={data?.dailyResume?.map((x) => ({
            key: x.day,
            ...x,
          }))}
        />
        <br />
        <br />
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 30 }}
          locale={{ emptyText: 'Sin datos' }}
          columns={allRenewalsColumns}
          dataSource={data?.allRenewals?.map((x) => ({
            key: x.renewal_id,
            ...x,
          }))}
        />

      </div>
    </div>
  );
};
DashboardModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};
export default DashboardModule;
