import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from '@fortawesome/free-solid-svg-icons';

export const fleets = {
  1: 'Grin Chile',
  2: 'Grin Perú',
};
export const pointNamesByType = {
  house: 'Domicilio',
  work: 'Trabajo',
};

export const brands = {
  1: {
    GRIN: 'Grin',
    BANCO_FALABELLA: 'Banco Falabella',
    MALL_MARINA: 'Mall Marina',
  },
  2: { GRIN: 'Grin' },
  3: {
    GRIN: 'Grin',
    BANCO_FALABELLA: 'Banco Falabella',
    MALL_MARINA: 'Mall Marina',
  },
  4: { GRIN: 'Grin' },
  5: { GRIN: 'Grin' },
  6: {
    GRIN: 'Grin',
    BANCO_FALABELLA: 'Banco Falabella',
    MALL_MARINA: 'Mall Marina',
  },
  7: {},
  8: {},
  9: { GRIN: 'Grin' },
  10: {},
  11: {},
  12: {},
  13: {},
  14: {},
};
export const alertTypes = {
  THEFT: 'Posible Robo',
  MOVEMENT: 'Patín caido',
  PUSHING: 'Patin empujado',
};
export const documentTypes = {
  contract: 'Contrato',
  delivery_proof: 'Prueba de entrega',
  pickup_proof: 'Prueba de retiro',
};

export const networks = {
  1: '2G',
  2: '4G',
};

export const subscriptionStatusColor = {
  NON_PAID: '#c30',
  PAID: '#39c',
  READY_FOR_SHIPPING: '#6e8f40',
  ACTIVE: '#090',
  FINISHED: '#444',
  RENEWAL_REQUIRED: '#f60',
};

export const subscriptionItemStatusColor = {
  NON_ASSIGNED: '#c30',
  ASSIGNED: '#090',
};

export const renewalStatusColor = {
  NON_PAID: '#c30',
  COMPLETE_PAYMENT: '#fc0',
  PAID: '#090',
};

export const colorZone = {
  ALLOW: '#ffccaa',
  DENY: '#cc3300',
  MICROZONE: '#cc66cc',
  MICROZONE_WAREHOUSE: '#666666',
  DENY_END_TRIP: '#222222',
  TRIP_MICROZONE: '#ff00cc',
};

export const getBatteryIcon = (n) => {
  if (n > 100 * (7 / 8)) {
    return faBatteryFull;
  }
  if (n > 100 * (5 / 8)) {
    return faBatteryThreeQuarters;
  }
  if (n > 100 * (3 / 8)) {
    return faBatteryHalf;
  }
  if (n > 100 * (1 / 8)) {
    return faBatteryQuarter;
  }
  return faBatteryEmpty;
};

export const getSimProviderByIccid = (iccid) => {
  if (iccid.startsWith('8988')) {
    return 'Twilio';
  }
  return 'Entel';
};

export const statusTranslations = {
  in_warehouse: 'En bodega',
  sold: 'Vendido',
  in_warehouse_need_test: 'En bodega, en espera de salir a prueba',
  in_transport_to_deploy: 'En transporte a despliegue',
  in_transport_to_test: 'En transporte a pruebas',
  disabled: 'Deshabilitado',
  in_street: 'Total en calle',
  missing_connection: 'Sin conexión',
  motorist: 'Motorizado',
  in_transport_to_warehouse: 'En transporte a bodega',
  live: 'Desplegado',
  damaged: 'Dañado',
  maintenance: 'En mantenimiento',
  maintenance_waiting_spare: 'En mantenimiento, En espera de repuestos',
  in_transport_to_grin4u_user: 'En transporte a usuario Grin 4U',
  user_grin4u: 'En poder de usuario Grin 4U',
  stolen_suspect: 'Sospecha de robo',
  stolen: 'Robado',
  available: 'Disponible',
  non_available: 'No disponible',
  in_ride: 'En viaje',
  reserved: 'Reservado',
  no_status: 'Sin Estado',
  deleted: 'Eliminado',
  recovered: 'Recuperado',
  scheduled_to_pickup: 'Programado para retiro',
  in_transport_to_user: 'En transporte a cliente',
  scrap: 'Scrap',
  customer: 'En poder de cliente',
};

export const contractTypesTranslations = {
  BOLETA_HONORARIOS: 'Honorarios',
  CONTRATO_PLAZO_FIJO: 'Contrato Plazo Fijo',
  CONTRATO_INDEFINIDO: 'Contrato Indefinido',
};
export const fleetNames = {
  1: 'Grin Santiago',
  2: 'Grin Perú',
  3: 'pviojo',
  4: 'Grin4U Chile',
  5: 'Grin4U Perú',
  6: 'Grin Viña del Mar',
  7: 'Grin Santiago Parque',
  9: 'Iván',
  11: 'Grin Colombia',
};

export const rolesTranslations = {
  transportist: 'Transporte',
  motorist: 'Motorizado',
  activator: 'Activador en calle',
  warehouse_operator: 'Operador de bodega/Taller',
  warehouse_electronic: 'Operador de bodega/Taller Electrónico',
  react: 'React',
  cs: 'Atención a cliente',
  analyst: 'Analista',
  cs_manager: 'Encargado de Atención a Cliente',
  admin: 'Administrador',
  hr_manager: 'Encargado de Recursos Humanos',
  authority: 'Autoridad Municipalidades',
};

export const limaDistricts = [
  'Ancón', 'Ate', 'Barranco', 'Breña', 'Callao', 'Carabayllo', 'Cercado de Lima', 'Chaclacayo', 'Chorrillos', 'Cieneguilla', 'Comas', 'El agustino', 'Independencia', 'Jesús maría', 'La molina', 'La victoria', 'Lince', 'Los olivos', 'Lurigancho', 'Lurín', 'Magdalena del mar', 'Miraflores', 'Pachacámac', 'Pucusana', 'Pueblo libre', 'Puente piedra', 'Punta hermosa', 'Punta negra', 'Rímac', 'San bartolo', 'San borja', 'San isidro', 'San Juan de Lurigancho', 'San Juan de Miraflores', 'San Luis', 'San Martin de Porres', 'San Miguel',
  'Santa Anita', 'Santa María del Mar', 'Santa Rosa', 'Santiago de Surco', 'Surquillo', 'Villa el Salvador', 'Villa Maria del Triunfo',
];

export const santiagoDistricts = [
  'Cerrillos', 'Cerro Navia', 'Conchalí', 'El Bosque', 'Estación Central', 'Huechuraba', 'Independencia', 'La Cisterna', 'La Florida', 'La Granja', 'La Pintana', 'La Reina', 'Las Condes', 'Lo Barnechea', 'Lo Espejo', 'Lo Prado', 'Macul', 'Maipú', 'Ñuñoa', 'Pedro Aguirre Cerda', 'Peñalolén', 'Providencia', 'Pudahuel', 'Quilicura', 'Quinta Normal', 'Recoleta', 'Renca', 'San Joaquín', 'San Miguel', 'San Ramón', 'Santiago', 'Vitacura',
];

export const districts = {
  CL: santiagoDistricts,
  PE: limaDistricts,
};

export default {
  fleets,
  networks,
  limaDistricts,
  districts,
  santiagoDistricts,
  alertTypes,
  statusTranslations,
  fleetNames,
  getSimProviderByIccid,
  contractTypesTranslations,
  pointNamesByType,
  subscriptionItemStatusColor,
  rolesTranslations,
  colorZone,
  getBatteryIcon,
  brands,
  documentTypes,

};
