import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {Badge, Button, Table} from 'antd';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import {subscriptionItemStatusColor} from '../../helpers/params';

const SubscriptionItemsTable = ({items, onAction, manageItem}) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'QR',
      dataIndex: 'item',
      key: 'qr',
      render: (v, r) => (
        <>
          <Link to={`/device/${v.qr}`}>{v.qr}</Link>{' '}
          {r.status === 'ASSIGNED' && <>({v.status_tr})</>}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      align: 'center',
      key: 'status_tr',
      render: (v, r) => (
        <Badge
          count={v}
          showZero
          color={subscriptionItemStatusColor[r.status]}
        />
      ),
    },
    {
      title: 'Comienzo',
      dataIndex: 'start_at',
      key: 'start_at',
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Fin',
      dataIndex: 'end_at',
      key: 'end_at',
      render: (v) => (v ? moment.utc(v).local().format('YYYY-MM-DD') : '-'),
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'right',
      key: 'tools',
      render: (id, r) => (
        <>
          {manageItem && r.status === 'ASSIGNED' && (
            <div>
              <Button onClick={() => onAction(id, 'mark_returned')}>
                Marcar como retornado
              </Button>{' '}
              <Button danger onClick={() => onAction(id, 'mark_stolen')}>
                Marcar como ROBADO
              </Button>
            </div>
          )}
          {manageItem && r.status === 'PICKED' && (
            <Button onClick={() => onAction(id, 'mark_returned')}>
              Marcar como retornado
            </Button>
          )}
        </>
      ),
    },
  ].filter((x) => !!x);

  return (
    <Table
      size='small'
      pagination={{hideOnSinglePage: true, defaultPageSize: 20}}
      locale={{emptyText: 'Sin items asignados'}}
      columns={columns}
      dataSource={
        items
          ? items.map((d) => ({
              key: d.id,
              ...d,
            }))
          : []
      }
    />
  );
};

SubscriptionItemsTable.defaultProps = {
  items: [],
  onAction: () => {},
  manageItem: false,
};
SubscriptionItemsTable.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func,
  manageItem: PropTypes.bool,
};

export default SubscriptionItemsTable;
