/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import isMobile from "is-mobile";
import {Link, useHistory} from "react-router-dom";

import {Button, Input, Table, Badge, Tabs} from "antd";
// import { useSearchableTable } from '../../hooks/useSearchableTable';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import useQuery from "../../hooks/useQuery";
import {findUsersAdvanced} from "../../services/users";

import * as grin4uService from "../../services/grin4u";

import styles from "./index.module.scss";
import Loading from "../../components/Loading";
import stylesLayout from "../../common/layout.module.scss";

const {TabPane} = Tabs;

const CustomersModule = ({selectedFleet}) => {
  const params = useQuery();
  const qParam = params.get("q") || "";
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [q, setQ] = useState(null);
  const [results, setResults] = useState(null);
  const [usersByStatus, setUsersByStatus] = useState(null);

  // const getColumnSearchProps = useSearchableTable();

  const loadUsersByStatus = async () => {
    setLoading(true);
    const [rspInProcess] = await Promise.all([
      grin4uService.getUsersByStatus("in_process", selectedFleet.cost?.country),
    ]);

    const rspUsersByStatus = {};

    if (rspInProcess?.status) {
      rspUsersByStatus.in_process = rspInProcess.data;
    }
    setUsersByStatus(rspUsersByStatus);
    setLoading(false);
  };

  const onStartSearch = async (forceQ) => {
    const searchQ = (forceQ || q).replaceAll("\n", ",");
    if (!searchQ) {
      return;
    }
    const newUrl = `/clientes/?q=${encodeURIComponent(searchQ)}`;
    if (newUrl !== `${history.location.pathname}${history.location.search}`) {
      history.push(newUrl);
    }
    setLoadingSearch(true);
    const rspUsers = await findUsersAdvanced({
      q: searchQ,
    });

    const r = {users: []};
    if (rspUsers && rspUsers.status && rspUsers?.data.length > 0) {
      r.users = rspUsers.data;
    }
    setResults(r);
    setLoadingSearch(false);
  };

  useEffect(async () => {
    if (qParam && qParam !== q) {
      setQ(qParam);
      onStartSearch(qParam);
    }
  }, [qParam]);

  useEffect(() => {
    loadUsersByStatus();
  }, [selectedFleet.id]);

  const usersColumns = [
    {
      title: "Id",
      key: "id",
      dataIndex: "id",
      render: (id) => (
        <>
          <div>
            <Link to={`/cliente/${id}`}>{id}</Link>
          </div>
        </>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (name) => name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => email,
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => phone,
    },
    {
      title: "Origen Lead",
      dataIndex: "data",
      key: "origin",
      render: (v) => v.grin4uinfo?.origin,
    },
    {
      title: "Estado Lead",
      dataIndex: "data",
      key: "lead_status",
      render: (v) => v.grin4uinfo?.lead_status,
    },
  ];

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div className={stylesLayout.secAction} style={{marginTop: -5}}>
          <Button onClick={() => history.push("/cliente/crear")} size='medium'>
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}} /> Crear
            nuevo cliente
          </Button>
        </div>
        Clientes
      </div>
      <div className={stylesLayout.content}>
        {isMobile() && (
          <div className={styles.input}>
            <Input
              value={q}
              placeholder='Buscar por email o teléfono...'
              onChange={(e) => setQ(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? onStartSearch() : null)}
            />
            <Button
              type='primary'
              onClick={() => onStartSearch()}
              disabled={!q || loading}
            >
              {loading ? "Cargando" : "Buscar"}
            </Button>
          </div>
        )}
        {(loadingSearch || results) && (
          <div>
            {loadingSearch ? (
              <Loading />
            ) : results ? (
              <div className={stylesLayout.box}>
                {!loading && !results?.users.length && (
                  <div className={stylesLayout.message}>No hay resultados</div>
                )}

                {!loading && results?.users && results.users.length > 0 && (
                  <>
                    <div className={styles.table}>
                      <Table
                        loading={loading}
                        size='small'
                        pagination={{
                          hideOnSinglePage: true,
                          defaultPageSize: 25,
                        }}
                        locale={{emptyText: "Sin datos"}}
                        columns={usersColumns}
                        dataSource={results.users.map((d) => ({
                          key: d.id,
                          ...d,
                        }))}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              "No hay resultados"
            )}
          </div>
        )}
        <div>
          {!loading ? (
            <>
              <br />
              <Tabs>
                <TabPane
                  tab={
                    <>
                      Clientes en proceso de creación{" "}
                      <Badge
                        showZero
                        count={usersByStatus?.in_process.length || 0}
                        color='var(--mainColor)'
                      />
                    </>
                  }
                  key='in_process'
                >
                  <Table
                    loading={loading}
                    size='small'
                    pagination={{hideOnSinglePage: true, defaultPageSize: 25}}
                    locale={{emptyText: "Sin datos"}}
                    columns={usersColumns}
                    dataSource={usersByStatus?.in_process.map((d) => ({
                      key: d.id,
                      ...d,
                    }))}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <>
                      Clientes creados recientemente{" "}
                      <Badge
                        showZero
                        count={usersByStatus?.active?.length || 0}
                        color='var(--mainColor)'
                      />
                    </>
                  }
                  key='active'
                >
                  <Table
                    loading={loading}
                    size='small'
                    pagination={{hideOnSinglePage: true, defaultPageSize: 25}}
                    locale={{emptyText: "Sin datos"}}
                    columns={usersColumns}
                    dataSource={usersByStatus?.active?.map((d) => ({
                      key: d.id,
                      ...d,
                    }))}
                  />
                </TabPane>
              </Tabs>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

CustomersModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};
export default CustomersModule;
