/* eslint-disable prefer-template */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Select, Space,
  Form, Button, notification, Radio, Row, Col, Badge, DatePicker, Checkbox,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';

import * as grin4uService from '../../services/grin4u';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';
import Loading from '../../components/Loading';
import SubscriptionsTable from '../../components/SubscriptionsTable';
import { formatMoneyFromFleet } from '../../helpers/utils';

const CustomerSubscriptionsModule = ({
  customer,
  country,
  selectedFleet,
  // eslint-disable-next-line no-unused-vars
  user,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [defineSubscriptionDates, setDefineSubscriptionDates] = useState(false);
  const [usedFields, setUsedFields] = useState({});
  const [saving, setSaving] = useState(false);
  const [checkingReferral, setCheckingReferral] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [referralDiscount, setReferralDiscount] = useState(null);

  const createSubscriptionEnabled = customer.grin4u_status === 'ACTIVE';

  const loadSubscriptions = async () => {
    setLoading(true);
    const rsp = await grin4uService.getUserSubscriptions(customer.id);

    if (rsp?.status) {
      setUserSubscriptions(rsp.data);
    } else {
      setUserSubscriptions([]);
    }
    setLoading(false);
    return true;
  };

  const calculateBreakdown = () => {
    const unitPrice = (selectedFleet.cost?.grin4u?.plans[usedFields.item_type]?.find(
      (plan) => plan.value === usedFields.plan,
    )?.price || 0);
    const { qty } = usedFields;
    const subtotal = unitPrice * qty;
    const discountReason = usedFields.discount_reason;
    const fieldDiscount = usedFields.discount ? +parseFloat(usedFields.discount.replaceAll(',', '.')).toFixed(2) : 0;
    const discount = Math.min(Math.max(fieldDiscount, 0), subtotal);
    const total = Math.max(subtotal - discount - (referralDiscount?.amount || 0), 0);
    return {
      qty,
      unitPrice,
      subtotal,
      referralDiscount,
      discount,
      discountReason,
      total,
    };
  };

  const onFinish = async (values) => {
    if (!createSubscriptionEnabled) {
      return null;
    }

    const selectedPlanData = selectedFleet.cost?.grin4u?.plans[usedFields.item_type]?.find(
      (plan) => plan.value === usedFields.plan,
    )?.data;
    const breakdown = calculateBreakdown();
    const durationDays = parseInt(values.plan.split('-')[0], 10);
    const payload = {
      type: `PLAN_${durationDays}`,
      item_type: values.item_type,
      total: breakdown.total,
      qty: values.qty,
      fleet_id: selectedFleet.id,
      user_id: customer.id,
      country,
      data: {
        breakdown,
        plan: selectedPlanData,
        duration_days: durationDays,
        shipping_point: values.shipping_point,
      },
    };

    if (defineSubscriptionDates) {
      if (usedFields.paid_at > usedFields.start_at) {
        notification.error({
          message: 'La fecha de pago de la suscripción no puede ser posterior a la de inicio',
          placement: 'top',
          key: 'result',
        });
        return false;
      }
      if (usedFields.end_at < usedFields.start_at) {
        notification.error({
          message: 'La fecha de fin de la suscripción no puede ser anterior a la de inicio',
          placement: 'top',
          key: 'result',
        });
        return false;
      }
      payload.start_at = usedFields.start_at.format('YYYY-MM-DD');
      payload.end_at = usedFields.end_at.format('YYYY-MM-DD');
      payload.paid_at = usedFields.paid_at.format('YYYY-MM-DD');
    }

    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Creando suscripción...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });

    const rsp = await grin4uService.createSubscription(payload);

    if (rsp?.status) {
      notification.success({
        message: 'Suscripción creada.',
        placement: 'top',
        key: 'result',
      });
      loadSubscriptions();
      setShowFormCreate(false);
    } else {
      notification.error({
        message: 'Error al crear suscripción.',
        placement: 'top',
        key: 'result',
      });
    }
    setSaving(false);
    return true;
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    loadSubscriptions();
  }, [customer.id]);

  const checkReferralCode = async () => {
    setCheckingReferral(true);
    const referralCode = form.getFieldValue('referralCode');
    const rsp = await grin4uService.checkReferralCode(customer.id, referralCode);
    console.log('rsp', rsp);
    if (rsp?.status) {
      const amount = selectedFleet.cost?.grin4u?.referral_discount.referred;
      setReferralDiscount({
        ...rsp.data,
        amount,
      });
      console.log('referral info', rsp.data, amount);
    } else {
      setReferralDiscount(null);
      notification.error({
        message: 'El código de referido no es válido.',
        placement: 'top',
        key: 'result',
      });
    }
    setCheckingReferral(false);
  };

  const fieldItemType = usedFields.item_type;
  const breakdown = calculateBreakdown();
  const { total } = breakdown;

  return (
    <div className={styles.cnt}>
      {createSubscriptionEnabled && (
        <Button
          onClick={() => {
            form.resetFields();
            setShowFormCreate((s) => !s);
          }}
          type="secondary"
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
          {' '}
          Crear Suscripción
        </Button>
      )}
      {!createSubscriptionEnabled && (
        <div style={{
          marginTop: 40, fontWeight: 'bold', color: '#c30', textAlign: 'center',
        }}
        >
          * No puedes crear suscripciones porque el cliente no tiene todos los datos completos
        </div>
      )}
      {showFormCreate && (
        <div className={[styles.createForm, stylesForm.fields].join(' ')}>
          <Form
            name="basic"
            shouldUpdate
            form={form}
            initialValues={{
              qty: 1,
              referralCode: 'PABLO-218631',
              discount: 0,
            }}
            layout="vertical"
            onValuesChange={(x, allValues) => {
              setUsedFields(allValues);
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
          >
            <Row gutter={24}>

              <Col sm={6} xs={24}>
                <Form.Item
                  label="Tipo de patín"
                  name="item_type"
                  rules={[{ required: true, message: 'El Tipo de patín es requerido' }]}
                >
                  <Radio.Group>
                    <Radio value="SKICK">SKICK</Radio>
                    <Radio value="SMAX">SMAX</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={4} xs={24}>
                <Form.Item
                  label="Cantidad"
                  name="qty"
                  rules={[{ required: true, message: 'La cantidad es requerida' }]}
                >
                  <Input placeholder="" type="number" min={1} />
                </Form.Item>
              </Col>
              <Col sm={14} xs={24}>
                <Form.Item
                  label="Plan"
                  name="plan"
                  rules={[{ required: true, message: 'El Plan es requerido' }]}
                >
                  <Select
                    options={
                      fieldItemType ? selectedFleet.cost?.grin4u?.plans[fieldItemType].map((x) => ({
                        ...x,
                        label: (
                          <>
                            <Badge
                              count={x.current ? 'Actual' : 'Anterior'}
                              color={x.current ? '#090' : 'c30'}
                              style={{ marginRight: 5 }}
                            />
                            {x.label + ' - ' + formatMoneyFromFleet(x.price, selectedFleet)}
                          </>),
                      })) : []
                    }
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col sm={12} xs={24}>
                <Form.Item
                  label="Código de Referido"
                  name="referralCode"
                >
                  <Space>
                    <Input placeholder="" min={0} onFocus={(e) => e.target.select()} />
                    <Button onClick={checkReferralCode}>
                      {checkingReferral && <FontAwesomeIcon icon={faSpinner} spin />}
                      {!checkingReferral && <>Verificar código</>}
                    </Button>
                  </Space>
                </Form.Item>
                {referralDiscount
                  ? (
                    <div style={{ marginBottom: 20, fontWeight: 'bold' }}>
                      Descuento por referido:
                      {' '}
                      {formatMoneyFromFleet(referralDiscount.amount, selectedFleet)}
                    </div>
                  )
                  : ''}

              </Col>
            </Row>
            <Row gutter={24}>
              <Col sm={12} xs={24}>
                <Form.Item
                  label="Descuento adicional"
                  name="discount"
                >
                  <Input placeholder="" type="" min={0} onFocus={(e) => e.target.select()} />

                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item
                  label="Motivo del descuento"
                  name="discount_reason"
                >
                  <Select
                    options={[
                      { label: 'Club Intercorp', value: 'intercorp' },
                      { label: 'Los Heroes', value: 'los-heroes' },
                      { label: 'Otro', value: 'otro' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="Punto de entrega"
                  name="shipping_point"
                  rules={[{ required: true, message: 'El Punto de entrega es requerido' }]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="house">
                        Domicilio -
                        {' '}
                        {customer.data?.grin4uinfo.house_address}
                        ,
                        {' '}
                        {customer.data?.grin4uinfo.house_district}
                      </Radio>

                      <Radio value="work" disabled={!customer.data?.documents.cert_work}>
                        Trabajo -
                        {' '}
                        {customer.data?.grin4uinfo.work_address}
                        ,
                        {' '}
                        {customer.data?.grin4uinfo.work_district}
                        {!customer.data?.documents.cert_work
                          && (
                            <span>
                              {' '}
                              * Para poder entregar en el trabajo es necesario
                              {' '}
                              completar el certificado de trabajo
                            </span>
                          )}
                      </Radio>

                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="Total a pagar"
                  name="total"
                >
                  {formatMoneyFromFleet(total, selectedFleet)}
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: 20 }}>
              <Col sm={24} xs={24}>
                <Checkbox
                  defaultChecked={!!defineSubscriptionDates}
                  onChange={(e) => {
                    form.setFieldValue('paid_at', null);
                    form.setFieldValue('start_at', null);
                    form.setFieldValue('end_at', null);
                    setDefineSubscriptionDates(e.target.checked);
                  }}
                >
                  Definir fechas de la suscripción (solo para suscripciones antiguas)
                </Checkbox>
                <br />

              </Col>
            </Row>
            {!!defineSubscriptionDates
              && (
                <>
                  <br />
                  <strong style={{ color: '#f60' }}>* Al crear esta suscripción con fechas quedará registrada como pagada y activa</strong>
                  <br />
                  <br />
                  <Row gutter={24}>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Fecha de pago de la suscripción"
                        name="paid_at"
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>

                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Fecha de inicio de la suscripción"
                        name="start_at"
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>

                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Fecha de fin de la suscripción"
                        name="end_at"
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>

                    </Col>
                  </Row>
                  <br />
                  <br />
                </>
              )}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={saving}>
                Guardar
              </Button>
              <Button type="link" style={{ color: '#f30' }} onClick={() => setShowFormCreate(false)} disabled={saving}>
                Cerrar
              </Button>
            </Form.Item>

          </Form>
        </div>
      )}
      {createSubscriptionEnabled
        && (
          <div>
            {loading
              ? <Loading />
              : (
                <div className={styles.subscriptions}>
                  <SubscriptionsTable
                    fleet={selectedFleet}
                    subscriptions={userSubscriptions}
                    show={['status']}
                  />
                </div>
              )}
          </div>
        )}
    </div>
  );
};
CustomerSubscriptionsModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
};
export default CustomerSubscriptionsModule;
