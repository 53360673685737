/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const create = async (payload) => executeAndWaitResponse(() => apiClient.notes.create(payload));
export const getForItem = async (itemType, itemId) => executeAndWaitResponse(() => apiClient.notes.getForItem(itemType, itemId));

export default {
  create,
  getForItem,
};
