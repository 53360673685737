/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  notification,
  Select,
  DatePicker,
  Alert,
  Col,
  Row,
} from 'antd';

import dayjs from 'dayjs';
import slugify from 'slugify';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';
import stylesLayout from '../../common/layout.module.scss';

import {register, getByEmail} from '../../services/users';

const RegisterModule = ({selectedFleet}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState(false);
  const [checkingExistsUser, setCheckingExistsUser] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const doRegister = async (values) => {
    setLoading(true);
    const rsp = await register({
      email: (values.email || '').trim(),
      name: (values.name || '').trim(),
      phone: (values.phone || '').trim(),
      grin4u_status: 'IN_PROCESS',
      referral_code:
        `${slugify(values.name ? values.name.replaceAll(' ', '') : '').substring(0, 5)}-${(values.phone ? values.phone.replaceAll(' ', '') : '').slice(-6)}`.toUpperCase(),
      password: '',
      data: {
        country: selectedFleet.cost?.country,
        grin4uinfo: {
          lead_status: values.lead_status,
          lead_date: values.lead_date.format('YYYY-MM-DD'),
          origin: values.origin,
        },
      },
      registration_complete: 1,
    });
    if (rsp?.status) {
      notification.success({
        message: `Cliente ${values.email} registrado correctamente`,
        placement: 'bottom',
        key: 'result',
      });
      history.push(`/cliente/${rsp.data.id}`);
    } else {
      notification.error({
        message: `Error al crear el cliente. ${rsp.msg}`,
        placement: 'bottom',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const checkExistsUser = async () => {
    setCheckingExistsUser(true);
    const rsp = await getByEmail(newEmail);
    if (rsp?.status) {
      setUserExists(rsp.data);
      setCheckingExistsUser(false);
      return false;
    }
    setUserExists(false);
    setCheckingExistsUser(false);
    return true;
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>Crear nuevo cliente</div>
      <div className={styles.cnt}>
        <div className={styles.login}>
          <div className={`${styles.form} ${stylesForm.form}`}>
            <div className={stylesForm.fields}>
              <Form
                name='basic'
                labelCol={{span: 2}}
                wrapperCol={{span: 22}}
                autoComplete='off'
                form={form}
                initialValues={{email: '', name: '', lead_date: dayjs()}}
                onFinish={doRegister}
                size='large'
              >
                <div style={{position: 'relative'}}>
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={[{required: true, message: 'El email es requerido'}]}
                  >
                    <Input
                      placeholder='Email...'
                      type='email'
                      onChange={(e) => setNewEmail(e.target.value)}
                      onBlur={checkExistsUser}
                    />
                  </Form.Item>
                  {checkingExistsUser && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{
                        position: 'absolute',
                        zIndex: 10,
                        right: 10,
                        top: 10,
                        fontSize: 20,
                        color: 'var(--mainColor)',
                      }}
                    />
                  )}
                  {!checkingExistsUser && userExists && (
                    <Row>
                      <Col offset={2} sm={22}>
                        <Alert
                          style={{marginBottom: 'var(--spacing)'}}
                          message={
                            <>
                              * El cliente {userExists.email} ya existe:{' '}
                              <Link to={`cliente/${userExists.id}`}>
                                Ver aquí
                              </Link>
                            </>
                          }
                          type='error'
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                <Form.Item
                  label='Nombre'
                  name='name'
                  rules={[{required: true, message: 'El nombre es requerido'}]}
                >
                  <Input placeholder='Nombre...' type='text' />
                </Form.Item>
                <Form.Item label='Teléfono' name='phone'>
                  <Input placeholder='Teléfono (+51XXXXXXXXX)...' type='text' />
                </Form.Item>
                <Form.Item label='Origen' name='origin'>
                  <Select
                    options={[
                      'Ad FB',
                      'Ad IG',
                      'Ad Web',
                      'Ad Lead Ads',
                      'Ad Link Ads',
                      'Ad Google Generic',
                      'Ad Google Brand',
                      'Ad Whatsapp',
                      'Inbox',
                      'DM',
                      'Whatsapp',
                      'Landing',
                      'Orgánico',
                      'Ad Awareness FB',
                      'Ad Awareness IG',
                      'Ad Tráfico Chat WhatsApp FB',
                      'Ad Tráfico Chat WhatsApp IG',
                      'Ad Tráfico Web',
                      'Ad Interacción FB',
                      'Ad Interacción IG',
                      'Ad Interacción Feed',
                    ].map((x) => ({label: x, value: x}))}
                  />
                </Form.Item>
                <Form.Item label='Estado del Lead' name='lead_status'>
                  <Select
                    options={[
                      'No contactado',
                      'Contactado',
                      'Espera de datos',
                      'Fuera de Cobertura',
                      'Espera de voucher',
                      'Concretado',
                      'No está interesado',
                      'No respondió',
                      'Cliente problemático',
                      'No tiene WhatsApp',
                      'Recontactado',
                    ]
                      .sort()
                      .map((x) => ({label: x, value: x}))}
                    showSearch
                  />
                </Form.Item>
                <Form.Item label='Fecha del Lead' name='lead_date'>
                  <DatePicker style={{width: '100%'}} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={loading || checkingExistsUser || userExists}
                  >
                    Crear nuevo cliente
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterModule.defaultProps = {};

RegisterModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};

export default RegisterModule;
