import React from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

const WebcamCapture = ({ onCapture, onClose }) => {
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        onCapture(imageSrc);
      }
    },
    [webcamRef],
  );
  return (
    <>
      <div className={styles.webcam}>
        <div className={styles.video}>
          <Webcam
            audio={false}
            height={window.innerHeight}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={window.innerWidth}
            videoConstraints={{
              aspectRatio: window.innerWidth / window.innerHeight,
              width: window.innerWidth,
              height: window.innerHeight,
              facingMode: 'environment',
            }}
          />
        </div>
        <button onClick={onClose} type="button" className={styles.close}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <button onClick={capture} type="button" className={styles.button}>
          <FontAwesomeIcon icon={faCamera} />
        </button>
      </div>
    </>
  );
};

WebcamCapture.propTypes = {
  onCapture: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default WebcamCapture;
