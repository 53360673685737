export default (client, defaultParams) => ({
  getTenant: (payload) => (
    client('/user/tenant', {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  find: (payload) => (
    client(`/users/find?q=${encodeURIComponent(payload.q)}`, {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  findUsersAdvanced: (payload) => (
    client(`/admin/users/find?q=${encodeURIComponent(payload.q)}`, {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  register: (payload) => (
    client('/admin/user/create', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  assignPromocodeToUser: (userId, promocodeId) => (
    client(`/admin/promocode/assign/${userId}/${promocodeId}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  createPromocodeForUser: (payload) => (
    client('/admin/promocode/create_for_user', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  modifyPassword: (payload) => (
    client('/admin/user/modify_password', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  addRole: (id, role) => (
    client(`/admin/user/${id}/role/${role}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  removeRole: (id, role) => (
    client(`/admin/user/${id}/role/${role}`, {
      method: 'DELETE',
      params: {
        ...defaultParams,
      },
    })
  ),

  addFleet: (id, fleetId) => (
    client(`/admin/user/${id}/fleet/${fleetId}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  removeFleet: (id, fleetId) => (
    client(`/admin/user/${id}/fleet/${fleetId}`, {
      method: 'DELETE',
      params: {
        ...defaultParams,
      },
    })
  ),

  updatePaymentMultiplier: (id, value) => (
    client(`/admin/user/${id}/payment_multiplier`, {
      method: 'PUT',
      data: { multiplier: parseFloat(value) },
      params: {
        ...defaultParams,
      },
    })
  ),

  updatePhone: (id, phone) => (
    client(`/admin/user/${id}/phone`, {
      method: 'PUT',
      data: { phone },
      params: {
        ...defaultParams,
      },
    })
  ),
  updateName: (id, name) => (
    client(`/admin/user/${id}/name`, {
      method: 'PUT',
      data: { name },
      params: {
        ...defaultParams,
      },
    })
  ),
  updateDni: (id, dni) => (
    client(`/admin/user/${id}/dni`, {
      method: 'PUT',
      data: { dni },
      params: {
        ...defaultParams,
      },
    })
  ),
  updateContract: (id, contract) => (
    client(`/admin/user/${id}/contract`, {
      method: 'PUT',
      data: { contract },
      params: {
        ...defaultParams,
      },
    })
  ),

  updatePhoto: (id, file) => (
    client(`/admin/user/${id}/photo`, {
      method: 'PUT',
      data: { file },
      params: {
        ...defaultParams,
      },
    })
  ),

  updateCustomerDocument: (id, documentId, file) => (
    client(`/admin/user/${id}/document`, {
      method: 'PUT',
      data: { documentId, file },
      params: {
        ...defaultParams,
      },
    })
  ),

  updateCountry: (id, country) => (
    client(`/admin/user/${id}/country`, {
      method: 'PUT',
      data: { country },
      params: {
        ...defaultParams,
      },
    })
  ),

  changeWorkingStatus: (id, status) => (
    client(`/admin/user/${id}/change_working_status/${status}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  login: (payload) => (
    client('/login', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  me: (payload) => (
    client('/user/me', {
      method: 'GET',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id, includeEvents = false) => (
    client(`/admin/user/${id}?${includeEvents ? 'include_events=1' : ''}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getByHash: (hash) => (
    client(`/admin/user/${hash}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getByEmail: (hash) => (
    client(`/admin/user/by_email/${hash}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  forgetPassword: (payload) => (
    client('/user/forget_password', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  changePassword: (payload) => (
    client('/user/change_password', {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  setStatus: (id, status) => (
    client(`/admin/user/${id}/status/${status}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  setReferralCode: (id, referralCode) => (
    client(`/admin/user/${id}/set_referral_code/${referralCode}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  setCardStatus: (id, status) => (
    client(`/admin/card/${id}/status/${status}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  updateGrin4UInfo: (id, payload) => (
    client(`/admin/user/${id}/update_grin4uinfo`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      no_login: 'Email o contraseña incorrecta',
    };
    return (messages[k] || k);
  },
});
