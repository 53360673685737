export default (client, defaultParams) => ({
  create: (payload) => (
    client('/admin/route', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
  get: (id) => (
    client(`/admin/route/${id}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getForFleet: (fleetId) => (
    client(`/admin/routes/fleet/${fleetId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getToday: (fleetId) => (
    client(`/admin/routes/fleet_today/${fleetId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getForFleetDate: (fleetId, date) => (
    client(`/admin/routes/fleet_date/${fleetId}/${date}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  update: (id, payload) => (
    client(`/admin/route/${id}`, {
      method: 'PUT',
      data: payload,
      params: {
        ...defaultParams,
      },
    })
  ),
});
