/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SortableListWrapper, { SortableItem } from 'react-easy-sort';

const arrayMove = (arr, fromIndex, toIndex) => {
  const newArr = [...arr];
  const element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};
const SortableList = ({ items, onChange }) => {
  const [els, setEls] = useState(items);
  useEffect(() => {
    setEls(items);
  }, [items]);
  const onSortEnd = (oldIndex, newIndex) => {
    setEls((prevEls) => {
      const newEls = arrayMove(prevEls, oldIndex, newIndex);
      onChange(newEls);
      return newEls;
    });
  };

  return (
    <SortableListWrapper onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
      {els.map((item) => (
        <SortableItem key={item.key}>
          <div className="item">{item.el}</div>
        </SortableItem>
      ))}
    </SortableListWrapper>
  );
};

SortableList.defaultProps = {
  items: [],
  onChange: () => { },
};

SortableList.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};
export default SortableList;
