import React, {useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {Badge, Button, Input, notification} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFile,
  faPlus,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import styles from "./index.module.scss";
import {renewalStatusColor} from "../../helpers/params";
import {humanFileSize} from "../../helpers/utils";

import * as subscriptionsService from "../../services/subscriptions";

import stylesLayout from "../../common/layout.module.scss";
import UploaderFile from "../UploaderFile";

const RenewalItem = ({subscription, renewal, user, onUpdate}) => {
  const [showForm, setShowForm] = useState(false);
  const [newReceiptAmount, setNewReceiptAmount] = useState(
    renewal.total - renewal.total_paid
  );
  const [newReceiptDocument, setNewReceiptDocument] = useState(null);

  const onAddDocument = async () => {
    if (!(newReceiptDocument && newReceiptAmount)) {
      return null;
    }
    const am =
      Math.round(
        parseFloat(newReceiptAmount.toString().replaceAll(",", ".")) * 100
      ) / 100;
    const pending =
      Math.round((renewal.total - renewal.total_paid) * 100) / 100;
    if (am > pending + 2) {
      notification.error({
        message: `El monto del comprobante ${am} es mayor al pendiente de pago ${pending}.`,
        placement: "top",
        key: "result",
      });
      return null;
    }
    const payload = {
      amount: +parseFloat(am).toFixed(2),
      document: newReceiptDocument,
    };
    notification.info({
      message: "Guardando comprobante...",
      placement: "top",
      key: "result",
    });
    const rsp = await subscriptionsService.addReceipt(renewal.id, payload);
    if (rsp?.status) {
      notification.success({
        message: "Comprobante guardado.",
        placement: "top",
        key: "result",
      });
      onUpdate();
    } else {
      notification.error({
        message: "Error al guardar comprobante.",
        placement: "top",
        key: "result",
      });
    }
    return null;
  };
  const validateRenewal = async () => {
    notification.info({
      message: "Validando pago...",
      placement: "top",
      key: "result",
    });
    const rsp = await subscriptionsService.validateRenewal(renewal.id);
    if (rsp?.status) {
      notification.success({
        message: "Pago Validado.",
        placement: "top",
        key: "result",
      });
      onUpdate();
    } else {
      notification.error({
        message: "Error al validar pago.",
        placement: "top",
        key: "result",
      });
    }
    return null;
  };
  const openForm = () => {
    setNewReceiptAmount((renewal.total - renewal.total_paid).toFixed(2));
    setNewReceiptDocument(null);
    setShowForm(true);
  };
  return (
    <div className={styles.renewal}>
      <div className={styles.info}>
        <div className={styles.id}># {renewal.id}</div>
        <div className={styles.created_at}>
          Creado:{" "}
          {moment.utc(renewal.created_at).local().format("YYYY-MM-DD HH:mm")}
        </div>
        {renewal.paid_at && (
          <div className={styles.created_at}>
            Fecha del pago:{" "}
            {renewal.paid_at
              ? moment.utc(renewal.paid_at).local().format("YYYY-MM-DD HH:mm")
              : "No pagado"}
          </div>
        )}
      </div>
      <div className={styles.pay_info}>
        <div className={styles.status}>
          <Badge
            count={renewal.status_tr}
            showZero
            color={renewalStatusColor[renewal.status]}
          />
          {renewal.status === "COMPLETE_PAYMENT" &&
            user.can.validate_payment_grin4u && (
              <div>
                <Button
                  type='secondary'
                  style={{
                    marginTop: "var(--spacing)",
                    marginBottom: "var(--spacing)",
                  }}
                  onClick={() => {
                    validateRenewal();
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} style={{marginRight: 5}} />
                  Confirmar pago
                </Button>
              </div>
            )}
        </div>
        <div className={styles.created_at}>
          <table className={stylesLayout.table} style={{textAlign: "left"}}>
            <tr>
              <td>Subtotal</td>
              <td style={{textAlign: "right", width: 120}}>
                {subscription.fleet.cost?.currency_symbol}{" "}
                {numeral(renewal.data.breakdown?.subtotal).format(
                  subscription.fleet.cost?.currency_format
                )}
              </td>
            </tr>
            {!!renewal.data.breakdown?.discount && (
              <tr>
                <td>
                  Descuento
                  <br />({renewal.data.breakdown?.discountReason})
                </td>
                <td style={{textAlign: "right"}}>
                  {subscription.fleet.cost?.currency_symbol}{" "}
                  {numeral(renewal.data.breakdown?.discount).format(
                    subscription.fleet.cost?.currency_format
                  )}
                </td>
              </tr>
            )}
            {!!renewal.data.breakdown?.referred_discount?.amount && (
              <tr>
                <td>Descuento por referir otros usuarios</td>
                <td style={{textAlign: "right"}}>
                  {subscription.fleet.cost?.currency_symbol}{" "}
                  {numeral(
                    renewal.data.breakdown?.referred_discount?.amount
                  ).format(subscription.fleet.cost?.currency_format)}
                </td>
              </tr>
            )}
            <tr>
              <td>Total a pagar</td>
              <td style={{textAlign: "right"}}>
                {subscription.fleet.cost?.currency_symbol}{" "}
                {numeral(renewal.data.breakdown?.total).format(
                  subscription.fleet.cost?.currency_format
                )}
              </td>
            </tr>
            <tr>
              <td>Pagado</td>
              <td style={{textAlign: "right"}}>
                {subscription.fleet.cost?.currency_symbol}{" "}
                {numeral(renewal.total_paid).format(
                  subscription.fleet.cost?.currency_format
                )}
              </td>
            </tr>
            <tr>
              <td>Pendiente</td>
              <td style={{textAlign: "right"}}>
                {subscription.fleet.cost?.currency_symbol}{" "}
                {numeral(renewal.total - renewal.total_paid).format(
                  subscription.fleet.cost?.currency_format
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className={styles.payment_records}>
        <div>
          <strong>Comprobantes de pago</strong>
        </div>
        {!["PAID", "VOID", "COMPLETE_PAYMENT"].includes(renewal.status) && (
          <div className={styles.add_receipt}>
            <Button
              type='secondary'
              style={{marginTop: 10}}
              onClick={() => {
                openForm();
              }}
            >
              <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}} />
              Agregar comprobante
            </Button>
          </div>
        )}
        {showForm && (
          <div className={styles.form}>
            <div>
              {!newReceiptDocument && (
                <UploaderFile
                  onUpload={({file}) => {
                    setNewReceiptDocument(file);
                  }}
                  render={({uploading, progress}) => (
                    <>
                      {!uploading && (
                        <div className={stylesLayout.uploader}>
                          <div className={stylesLayout.uploaderInner}>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{marginRight: 10}}
                            />{" "}
                            Haz click o arrastra un nuevo comprobante aquí
                          </div>
                        </div>
                      )}
                      {uploading &&
                        progress &&
                        progress.map((p) => (
                          <div className={styles.fileDetail}>
                            <div
                              className={styles.fileDetailProgress}
                              style={{width: `${p.percent} % `}}
                            />
                            <div className={styles.fileDetailInner}>
                              {p.percent >= 100 ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color='#093'
                                  style={{marginRight: 10, color: "#093"}}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  color='#999'
                                  style={{marginRight: 10, color: "#999"}}
                                />
                              )}
                              <div className={styles.fileDetailName}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: "80%",
                                  }}
                                >
                                  {p.name}
                                </span>
                                {p.percent >= 100 && (
                                  <span> - Guardando...</span>
                                )}
                              </div>
                              <div className={styles.fileDetailSize}>
                                {humanFileSize((p.size * p.percent) / 100)} de{" "}
                                {humanFileSize(p.size)}
                              </div>
                              <div className={styles.fileDetailPercent}>
                                {parseInt(p.percent, 10)}%
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                />
              )}
              {newReceiptDocument && (
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={newReceiptDocument.private_url}
                >
                  Ver recibo cargado
                </a>
              )}
            </div>
            <div>
              Monto: {subscription.fleet.cost?.currency_symbol}{" "}
              <Input
                style={{width: 200}}
                type='text'
                placeholder='Monto...'
                value={newReceiptAmount}
                onChange={(e) => setNewReceiptAmount(e.target.value)}
              />
            </div>
            <div>
              <Button
                disabled={!(newReceiptDocument && newReceiptAmount)}
                type='primary'
                onClick={() => {
                  onAddDocument();
                }}
              >
                <FontAwesomeIcon icon={faSave} style={{marginRight: 5}} />
                Guardar comprobante
              </Button>
            </div>
          </div>
        )}
        <div className={styles.receipts}>
          {renewal.data.receipts ? (
            renewal.data.receipts.map((c) => (
              <div className={styles.receipt}>
                <div>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={c.document.private_url}
                  >
                    {c.document.mimetype.startsWith("image/") ? (
                      <img
                        src={c.document.private_url}
                        alt=''
                        className={styles.receiptImage}
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faFile}
                          style={{fontSize: 24, marginRight: 10}}
                        />
                        <span>Ver comprobante</span>
                      </>
                    )}
                  </a>
                </div>
                <div className={styles.amount}>
                  {subscription.fleet.cost?.currency_symbol}{" "}
                  {numeral(c.amount).format(
                    subscription.fleet.cost?.currency_format
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={styles.no_message}>
              No hay comprobantes cargados
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

RenewalItem.propTypes = {
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  renewal: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default RenewalItem;
