/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const getLog = async (ref, type, limit = 200, beforeId = null) => executeAndWaitResponse(() => apiClient.devices.log(ref, type, limit, beforeId));
export const getEvents = async (ref, type, limit = 200, beforeId = null) => executeAndWaitResponse(() => apiClient.devices.events(ref, type, limit, beforeId));
export const getGeopositions = async (ref, type, hours = 24) => executeAndWaitResponse(() => apiClient.devices.geopositions(ref, type, hours));
export const deleteByTypeRef = async (ref, type) => executeAndWaitResponse(() => apiClient.devices.deleteByTypeRef(ref, type));
export const getStatusTransitionsAllowed = async (fleetId) => executeAndWaitResponse(() => apiClient.devices.statusTransitionsAllowed(fleetId));
export const setPosition = async (ref, lat, lng) => executeAndWaitResponse(() => apiClient.devices.setPosition(ref, lat, lng));
export const getBalanceSuggestions = async (fleetId, hours, minProb) => executeAndWaitResponse(() => apiClient.devices.getBalanceSuggestions(fleetId, hours, minProb));
export const setDeviceBrand = async (ref, brand) => executeAndWaitResponse(() => apiClient.devices.setBrand(ref, brand));
export const addDamage = async (ref, data) => executeAndWaitResponse(() => apiClient.devices.addDamage(ref, data));
export const fixDamage = async (ref, hash, data) => executeAndWaitResponse(() => apiClient.devices.fixDamage(ref, hash, data));
export const setDeviceQr = async (ref, qr) => executeAndWaitResponse(() => apiClient.devices.setQr(ref, qr));
export const setDeviceSerial = async (ref, type, value) => executeAndWaitResponse(() => apiClient.devices.setSerial(ref, type, value));
export const createDevice = async (payload) => executeAndWaitResponse(() => apiClient.devices.create(payload));
export const findNonLocatable = async (fleetId) => executeAndWaitResponse(() => apiClient.devices.findNonLocatable(fleetId));
export const getDeviceByTypeQRInFleet = async (qr, type) => executeAndWaitResponse(() => apiClient.devices.getByTypeQRInFleet(qr, type));
export const getEntelInfo = async (iccid) => executeAndWaitResponse(() => apiClient.devices.entelInfo(iccid));
export const getTwilioInfo = async (iccid) => executeAndWaitResponse(() => apiClient.devices.twilioInfo(iccid));
export const addReparation = async (ref, data) => executeAndWaitResponse(() => apiClient.devices.addReparation(ref, data));
export const setDeviceTags = async (ref, tags) => executeAndWaitResponse(() => apiClient.devices.setDeviceTags(ref, tags));
export const setIot = async (ref, newRef) => executeAndWaitResponse(() => apiClient.devices.setIot(ref, newRef));
export const removeIot = async (ref) => executeAndWaitResponse(() => apiClient.devices.removeIot(ref));
export const setGPS = async (ref, newGPS) => executeAndWaitResponse(() => apiClient.devices.setGPS(ref, newGPS));
export const removeGPS = async (ref) => executeAndWaitResponse(() => apiClient.devices.removeGPS(ref));

export const updateFirware = async (payload) => {
  try {
    const { data } = await apiClient.devices.updateFirware(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const findDevices = async ({
  position,
  fleetId = null,
  type = null,
  q = null,
  filters = null,
  radius = 25,
  output = 'full',
  status = [],
}) => {
  try {
    const { data } = await apiClient.devices.find({
      q, fleetId, filters, position, output, status, type, radius,
    });
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const getDeviceByTypeQROrRef = async ({
  code, type = 'scooter',
}) => {
  try {
    let fn = null;
    if (code.length <= 4) {
      fn = apiClient.devices.getByTypeQR;
    } else {
      fn = apiClient.devices.getByTypeRef;
    }
    const { data } = await fn(
      code,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.devices.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};
export const sendDeviceCommand = async ({
  ref, command, type = 'scooter', sync = false, useBackup = false,
}) => {
  try {
    const { data } = await apiClient.devices.sendCommand(
      ref,
      type,
      command,
      sync,
      useBackup,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.devices.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const requestPosition = async ({
  ref, type = 'scooter',
}) => sendDeviceCommand({
  ref,
  command: '201',
  type,
});

export const changeDeviceLightColor = async ({
  ref, type = 'scooter', mode, color,
}) => {
  try {
    const { data } = await apiClient.devices.changeLightColor(
      ref,
      type,
      mode,
      color,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.devices.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const requestQR = async ({
  ref, type = 'scooter',
}) => sendDeviceCommand({
  ref,
  command: '144',
  type,
});
export const lockDevice = async ({
  ref, type = 'scooter',
}) => {
  try {
    const { data } = await apiClient.devices.lock(
      ref,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.devices.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};
export const unlockDevice = async ({
  ref, type = 'scooter',
}) => {
  try {
    const { data } = await apiClient.devices.unlock(
      ref,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.devices.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const getCommandForAction = (action, params = null) => {
  switch (action.toLowerCase()) {
    case 'unlock':
      return '1,0';
    case 'lock':
      return '1,1';
    case 'power_off':
      return '17,1';
    case 'buzz_on':
      return '50,1';
    case 'get_max_speed':
      return '127';
    case 'set_max_speed':
      return `27,${params.speed * 10}`;
    case 'buzz_off':
      return '50,0';
    case 'alerts_on':
      return '57,1';
    case 'alerts_off':
      return '57,0';
    case 'force_heartbeat':
      return '58,1';
    case 'set_heartbeat':
      return `39,${params.period}`;
    case 'get_lock_status':
      return '101';

    case 'request_geolocation':
      return '201';
    case 'get_battery':
      return '202';
    case 'request_firmware':
      return '211';
    case 'change_light':
      switch (params.color.toLowerCase()) {
        case 'off':
          return '2,0,0,0,0,0';
        case 'alert':
          return '2,0,32,50,96,7';
        case 'red':
          return '2,0,0,0,0,1';
        case 'orange':
          return '2,14,14,14,14,1';
        case 'yellow':
          return '2,32,32,32,32,1';
        case 'green':
          return '2,50,50,50,50,1';
        case 'cyan':
          return '2,72,72,72,72,1';
        case 'blue':
          return '2,96,96,96,96,1';
        case 'violet':
          return '2,b4,b4,b4,b4,1';
        case 'pink':
          return '2,dc,dc,dc,dc,1';
        default:
          return null;
      }

    default:
      return null;
  }
};

export default {
  getCommandForAction,
  getLog,
  getEvents,
  setDeviceQr,
  createDevice,
  updateFirware,
  removeIot,
  setIot,
  removeGPS,
  setGPS,
  findDevices,
  findNonLocatable,
  setPosition,
  deleteByTypeRef,
  getGeopositions,
  getDeviceByTypeQROrRef,
  getDeviceByTypeQRInFleet,
  lockDevice,
  getEntelInfo,
  getTwilioInfo,
  requestPosition,
  requestQR,
  getStatusTransitionsAllowed,
  unlockDevice,
  getBalanceSuggestions,
  setDeviceBrand,
  addDamage,
  fixDamage,
  addReparation,
  setDeviceTags,
};
