/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import stylesLayout from '../../common/layout.module.scss';

const sizes = {
  sm: 24,
  lg: 48,
  md: 36,
};
const Loading = ({ styles, size }) => (
  <div style={styles} className={stylesLayout.loading}>
    <FontAwesomeIcon
      icon={faRotate}
      spin
      style={{
        fontSize: sizes[size] || sizes.md,
        color: 'var(--mainColor)',
      }}
    />
  </div>
);

Loading.defaultProps = {
  styles: {},
  size: 'md',
};

Loading.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
};
export default Loading;
