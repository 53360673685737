/* eslint-disable max-len */
import apiClient from "../api";
import {executeAndWaitResponse} from "./service";

export const setLeadStatus = async (id, status) =>
  executeAndWaitResponse(() => apiClient.leads.setStatus(id, status));

export default {
  setLeadStatus,
};
