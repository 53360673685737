/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Table,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as routesService from '../../services/routes';

import stylesLayout from '../../common/layout.module.scss';
import Loading from '../../components/Loading';
import RouteForm from '../../components/RouteForm';

const RoutesModule = ({
  selectedFleet,
}) => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [routes, setRoutes] = useState(null);

  const load = async (showLoading) => {
    if (showLoading) {
      setLoading(true);
    }
    const rsp = await routesService.getForFleet(selectedFleet.id);
    if (rsp?.status) {
      setRoutes(rsp.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    load(true);
  }, []);

  const openForm = () => {
    setShowForm(true);
  };

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        Rutas
        <div className={stylesLayout.secAction} style={{ marginTop: -5 }}>
          <Button onClick={() => { load(true); }} size="medium">
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>

      </div>
      <div className={stylesLayout.content}>
        <div className={stylesLayout.tools}>
          <Button type="secondary" onClick={() => { openForm(true); }} size="medium">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
            {' '}
            Crear Ruta
          </Button>
        </div>
        {showForm && (
          <div style={{ marginBottom: 100 }}>
            <RouteForm
              selectedFleetId={selectedFleet.id}
              onClose={() => {
                setShowForm(false);
              }}
              onChange={() => {
                setShowForm(false);
                load(true);
              }}
            />

          </div>
        )}
        {!loading ? (
          <>
            <Table
              size="small"
              pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
              locale={{ emptyText: 'Sin datos' }}
              columns={[
                {
                  title: '#',
                  dataIndex: 'id',
                  key: 'id',
                  render: (v, r) => <Link to={`/ruta/${r.id}`}>{v}</Link>,
                },
                {
                  title: 'Fecha',
                  dataIndex: 'date',
                  key: 'date',
                  render: (v, r) => <Link to={`/ruta/${r.id}`}>{v}</Link>,
                },
                {
                  title: 'Puntos a entregar',
                  dataIndex: 'data',
                  key: 'points',
                  render: (v) => <>{v.order?.length}</>,
                },
              ]}
              dataSource={routes && routes.map((x) => ({
                key: x.id,
                ...x,
              }))}
            />
          </>
        ) : <Loading />}
      </div>
    </div>
  );
};

RoutesModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
};

export default RoutesModule;
