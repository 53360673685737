import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";
import {Button, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDownload,
  faLaptop,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {faAndroid, faApple} from "@fortawesome/free-brands-svg-icons";
import * as horusService from "../../services/horus";
import stylesLayout from "../../common/layout.module.scss";
import Loading from "../../components/Loading";
import {setLeadStatus} from "../../services/leads";
import CopyWithMessage from "../../components/CopyWithMessage";

const campaignToName = {
  "": "ORGÁNICO",
  trafico_grin: "AD WEB",
  reconnecting_lead_ads_abo_performance_alwayson_abril: "AD LEAD ADS",
  reconnecting_link_ads_abo_performance_alwayson_abril: "AD LINK ADS",
  scooter_electrico_grin_kw_search_generic_2024: "AD GOOGLE GENERIC",
  scooter_electrico_grin_kw_search_brand_2024: "AD GOOGLE BRAND",
};
const LeadsModule = () => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState(false);

  const updateData = (key, value) => {
    setData((s) => ({
      ...s,
      [key]: value,
    }));
  };
  const loadAll = async () => {
    setStatus("loading");
    const queryNewLeads = `select 
    l.id,
    json_unquote(json_extract(l.data, '$.name')) as name, 
    json_unquote(json_extract(l.data, '$.email')) as email, 
    json_unquote(json_extract(l.data, '$.phone')) as phone,
    json_unquote(json_extract(l.data, '$.source')) as source,
    json_unquote(json_extract(l.data, '$.landingId')) as landingId,
    if (json_unquote(json_extract(l.data, '$.source'))='direct',coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_source')), 'direct'), json_unquote(json_extract(l.data, '$.source'))) as source,
    json_unquote(json_extract(l.data, '$.extraParams.referrer')) as referrer,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_medium')),'') medium,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_campaign')),'') campaign,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_term')),'') term,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_content')),'') content,
    coalesce(json_unquote(json_extract(l.data, '$.selectedPlan')),'') selectedPlan,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isMobile')),'') isMobile,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isAndroid')),'') isAndroid,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isIphone')),'') isIphone,
    l.status,
    u.id as user_id, 
    u.email as user_email,
    u.name as user_name,
    l.created_at,
    l.updated_at
    from 
      leads l
      left join users u on u.email=json_unquote(json_extract(l.data, '$.email'))
    where
      l.status='NEW'
      and l.complete IN (1)
    order by l.id asc;
    `;
    const queryRecentContactedLeads = `select 
    l.id,
    json_unquote(json_extract(l.data, '$.name')) as name, 
    json_unquote(json_extract(l.data, '$.email')) as email, 
    json_unquote(json_extract(l.data, '$.phone')) as phone,
    json_unquote(json_extract(l.data, '$.source')) as source,
    json_unquote(json_extract(l.data, '$.landingId')) as landingId,
    if (json_unquote(json_extract(l.data, '$.source'))='direct',coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_source')), 'direct'), json_unquote(json_extract(l.data, '$.source'))) as source,
    json_unquote(json_extract(l.data, '$.extraParams.referrer')) as referrer,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_medium')),'') medium,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_campaign')),'') campaign,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_term')),'') term,
    coalesce(json_unquote(json_extract(l.data, '$.extraParams.queryParams.utm_content')),'') content,
    coalesce(json_unquote(json_extract(l.data, '$.selectedPlan')),'') selectedPlan,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isMobile')),'') isMobile,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isAndroid')),'') isAndroid,
    coalesce(json_unquote(json_extract(l.data, '$._meta.device.isIphone')),'') isIphone,
    l.status,
    u.id as user_id, 
    u.email as user_email,
    u.name as user_name,
    l.created_at,
    l.updated_at
    from leads l
      left join users u on u.email=json_unquote(json_extract(l.data, '$.email'))
    where
      l.status='CONTACTED'
    order by
      l.updated_at desc
      limit 500;
    `;

    const [rspNewLeads, rspRecentContactedLeads] = await Promise.all([
      horusService.executeQuery(queryNewLeads),
      horusService.executeQuery(queryRecentContactedLeads),
    ]);

    if (rspNewLeads?.status) {
      updateData("newLeads", rspNewLeads.data);
    } else {
      updateData("newLeads", []);
    }
    if (rspRecentContactedLeads?.status) {
      updateData("recentContactedLeads", rspRecentContactedLeads.data);
    } else {
      updateData("recentContactedLeads", []);
    }
    setStatus("loaded");
  };

  useEffect(() => {
    loadAll();
  }, []);

  if (status === "loading") {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  const changeLeadStatus = async (id, newStatus) => {
    const rsp = await setLeadStatus(id, newStatus);
    if (rsp.status) {
      loadAll();
    }
  };

  const leadsColumns = [
    {
      title: "Fecha del lead",
      dataIndex: "created_at",
      defaultSortOrder: "ascend",
      key: "created_at",
      align: "left",
      sorter: (a, b) =>
        moment(a.created_at).format("X") < moment(b.created_at).format("X"),
      render: (v) => moment.utc(v).local().format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Lead",
      dataIndex: "email",
      key: "email",
      align: "left",
      sorter: (a, b) => (a.email < b.email ? -1 : 1),
      render: (v, r) => (
        <div>
          <div>
            <CopyWithMessage
              inline
              value={r.name}
              style={{marginRight: 10}}
              messageCopied='Copiado!'
              messageTime={2000}
            >
              <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
            </CopyWithMessage>
            {r.name}
          </div>
          <div>
            <CopyWithMessage
              inline
              value={v}
              style={{marginRight: 10}}
              messageCopied='Copiado!'
              messageTime={2000}
            >
              <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
            </CopyWithMessage>
            {v}
          </div>
          {!!r.user_email && (
            <div>
              Usuario existente:{" "}
              <Link to={`/cliente/${r.user_id}`}>{r.user_name}</Link>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "left",
      sorter: (a, b) => (a.phone < b.phone ? -1 : 1),
      render: (v) =>
        v ? (
          <>
            <a
              href={`http://wa.me/${v.trim().replaceAll("+", "")}`}
              target='_blank'
              rel='noreferrer'
              style={{textDecoration: "underline", color: "#666"}}
            >
              {v}
            </a>
            <CopyWithMessage
              inline
              value={v}
              style={{marginLeft: 10}}
              messageCopied='Copiado!'
              messageTime={2000}
            >
              <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
            </CopyWithMessage>
          </>
        ) : null,
    },
    {
      title: "Origen",
      dataIndex: "source",
      key: "source",
      align: "left",
      sorter: (a, b) => (a.source < b.source ? -1 : 1),
      render: (v, r) => (
        <div>
          <div>
            <span style={{marginRight: 10, fontSize: 16}}>
              {r.isMobile === "true" ? (
                <>
                  <FontAwesomeIcon
                    style={{color: r.isIphone === "true" ? "#999" : "#090"}}
                    icon={r.isIphone === "true" ? faApple : faAndroid}
                    title={r.isIphone === "true" ? "iPhone" : "Android"}
                  />
                </>
              ) : (
                <FontAwesomeIcon icon={faLaptop} title='Computador' />
              )}
            </span>
            <span>
              <strong>{campaignToName[r.campaign] ?? r.campaign}</strong> ({v})
            </span>
          </div>
          {!!r.medium && (
            <div style={{fontSize: 12, color: "#999"}}>
              Ad: {r.content}
              <br />
              Ad Group: {r.term}
              <br />
              Campaign: {r.campaign}
            </div>
          )}
          {!!r.referrer && (
            <div style={{fontSize: 12, color: "#999"}}>
              Referrer: {r.referrer}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Plan interesado",
      dataIndex: "selectedPlan",
      key: "selectedPlan",
      align: "left",
      sorter: (a, b) => (a.selectedPlan < b.selectedPlan ? -1 : 1),
      render: (v) => (
        <div>
          {v.replace("plan_", "").replace("m", " mes")}
          {v !== "plan_1m" ? "es" : ""}
        </div>
      ),
    },

    {
      title: "Fecha de últ actualización",
      dataIndex: "updated_at",
      defaultSortOrder: "descend",
      key: "updated_at",
      align: "right",
      sorter: (a, b) =>
        moment(a.updated_at).format("X") < moment(b.updated_at).format("X"),
      render: (v) => moment.utc(v).local().format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "right",
      render: (v, r) => (
        <>
          {r.status === "NEW" && (
            <Button onClick={() => changeLeadStatus(r.id, "CONTACTED")}>
              Marcar como contactado
            </Button>
          )}
        </>
      ),
    },
  ];

  const csvData =
    data?.recentContactedLeads?.map((e) => ({
      name: e.name,
      email: e.email,
      phone: e.phone,
      fecha_lead: moment(e.created_at).format("YYYY-MM-DD"),
      selected_plan: e.selectedPlan,
    })) ?? [];

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div style={{float: "right"}}>
          <Button onClick={loadAll} size='large'>
            <FontAwesomeIcon icon={faSyncAlt} spin={status === "loading"} />
          </Button>
        </div>
        Leads Grin4U
      </div>
      <div className={stylesLayout.content}>
        <div>
          <div className={stylesLayout.sectionHeader}>Sin contactar</div>
          <Table
            size='small'
            pagination={{hideOnSinglePage: true, defaultPageSize: 30}}
            locale={{emptyText: "Sin datos"}}
            columns={leadsColumns}
            dataSource={data?.newLeads?.map((x) => ({
              key: x.month,
              ...x,
            }))}
          />
        </div>
        <div>
          <div
            className={stylesLayout.sectionHeader}
            style={{display: "flex", justifyContent: "space-between"}}
          >
            <div>Contactados reciente</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <CSVLink filename='leads.csv' data={csvData}>
                <Button type='secondary'>
                  <FontAwesomeIcon icon={faDownload} style={{marginRight: 5}} />
                  Exportar como CSV
                </Button>
              </CSVLink>
              <CopyWithMessage
                value={[
                  [
                    "name",
                    "email",
                    "phone",
                    "fecha_lead",
                    "selected_plan",
                  ].join(","),
                  ...csvData.map((x) =>
                    Object.values(x)
                      .map((xx) => `"${xx}"`)
                      .join(",")
                  ),
                ].join("\n")}
                messageCopied='Copiado!'
                messageTime={2000}
              >
                <Button type='secondary'>
                  <FontAwesomeIcon
                    icon={faCopy}
                    style={{fontSize: 16, marginRight: 5}}
                  />{" "}
                  Copiar Datos
                </Button>
              </CopyWithMessage>
            </div>
          </div>
          <Table
            size='small'
            pagination={{hideOnSinglePage: true, defaultPageSize: 30}}
            locale={{emptyText: "Sin datos"}}
            columns={leadsColumns}
            dataSource={data?.recentContactedLeads?.map((x) => ({
              key: x.month,
              ...x,
            }))}
          />
        </div>
      </div>
    </div>
  );
};
export default LeadsModule;
