/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'react-cmdk/dist/cmdk.css';
import CommandPalette, { filterItems, getItemIndex } from 'react-cmdk';

const AppCommandPalette = ({ items }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    function handleKeyDown(e) {
      if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'p') {
        e.preventDefault();
        e.stopPropagation();

        setOpen((currentValue) => !currentValue);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const filteredItems = filterItems(
    items,
    search,
  );

  return (
    <CommandPalette
      onChangeSearch={setSearch}
      placeholder="Ir a..."
      onChangeOpen={(isOpen) => {
        if (!isOpen) { setSearch(''); }
        setOpen(isOpen);
      }}
      search={search}
      isOpen={open}
    >
      {filteredItems.length ? (
        filteredItems.map((list) => (
          <CommandPalette.List key={list.id} heading={list.heading}>
            {list.items.map(({ id, children, ...rest }) => (
              <CommandPalette.ListItem
                key={id}
                index={getItemIndex(filteredItems, id)}
                showType={false}
                {...rest}
              >
                <small>{children}</small>
              </CommandPalette.ListItem>
            ))}
          </CommandPalette.List>
        ))
      ) : (
        <CommandPalette.FreeSearchAction />
      )}
    </CommandPalette>
  );
};

export default AppCommandPalette;
