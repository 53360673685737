import { INGEST_BASE_URL, PROMETHEUS_BASE_URL } from '../../environment';

export default (client, defaultParams) => ({

  metrics: () => (
    client(`${INGEST_BASE_URL}/metrics/json`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),

  prometheusLoad: (start, end) => (
    client(`${PROMETHEUS_BASE_URL}/api/v1/query_range?query=100+-+%28avg+by+%28job%29+%28irate%28node_cpu_seconds_total%7Bmode%3D%22idle%22%7D%5B1m%5D%29%29+*+100%29&start=${start}&end=${end}&step=15`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),

});
