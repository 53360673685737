/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState, useRef} from 'react';
import Notifier from 'react-desktop-notification';
import {HotKeys} from 'react-hotkeys';
import {useHistory, Route, Link} from 'react-router-dom';
import {usePosition} from 'use-position';
import isMobile from 'is-mobile';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Select, ConfigProvider, notification, Button, Input, Menu} from 'antd';
import locale from 'antd/lib/locale/es_ES';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faLocationArrow,
  faSignOutAlt,
  faTimesCircle,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import useOnlineStatus from 'react-online-hook';

// import ErrorBoundary from '../../components/ErrorBoundary';

import moment from 'moment';
import QRCode from 'react-qr-code';
import noSignal from '../../assets/no-signal.svg';

import logo from '../../assets/logo-rental.svg';

import pjson from '../../../package.json';
import styles from './index.module.scss';
import {createEvent} from '../../services/events';

import {getTenantOfUser} from '../../services/tenants';

import {getMe, changeWorkingStatus} from '../../services/users';

import {getAllFleets} from '../../services/fleets';

import {distance} from '../../helpers/utils';

import {setUser as setUserAction} from '../../modules/Auth/actions';

import ForbiddenModule from '../../modules/ForbiddenModule';
import Loading from '../../components/Loading';
import AppCommandPalette from '../../components/AppCommandPalette';

import stylesLayout from '../../common/layout.module.scss';
import CentrifugoSubscribeChannel from '../../components/CentrifugoSubscribeChannel';
import LocationPicker from '../../components/LocationPicker';

const {Option} = Select;
const keyMap = {
  TOGGLE_SIDEBAR: 's s b',
};
const AdminLayout = ({
  title,
  setUser,
  back,
  user,
  authorization,
  redirectTo,
  requiredPermissions,
  component,
  fixedInner,
  ...props
}) => {
  const position = usePosition(true, {enableHighAccuracy: false});
  const positionRef = useRef(position);
  positionRef.current = position;

  const [q, setQ] = useState(null);
  let defaultIsSidebarOpen = localStorage.getItem('__isSidebarOpen');
  defaultIsSidebarOpen =
    defaultIsSidebarOpen === null || defaultIsSidebarOpen === 'true';
  const history = useHistory();
  const [tenant, setTenant] = useState(null);
  const [showLocationMap, setShowLocationMap] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    isMobile() || defaultIsSidebarOpen
  );
  const [showCredential, setShowCredential] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(!isMobile());
  const [isForbidden, setIsForbidden] = useState(false);
  const [fleets, setFleets] = useState(null);
  const [selectedFleet, setSelectedFleet] = useState(
    localStorage.getItem('__selected_fleet')
  );
  const selectedFleetRef = useRef(selectedFleet);
  selectedFleetRef.current = selectedFleet;
  const {isOnline} = useOnlineStatus();

  const [loading, setLoading] = useState(true);
  const [lastNotifiedPosition, setLastNotifiedPosition] = useState(true);
  const [lastNotifiedPositionAt, setLastNotifiedPositionAt] = useState(null);

  const keyHandlers = {
    TOGGLE_SIDEBAR: () =>
      setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen),
  };

  const menuItems = [
    user?.can?.executive_grin4u
      ? {
          heading: 'Leads',
          id: 'leads',
          items: [
            {
              id: 'leads_all',
              children: 'Ver leads entrantes',
              onClick: () => {
                history.push('/leads');
              },
            },
          ].filter((x) => !!x),
        }
      : null,
    user?.can?.executive_grin4u || user?.can?.validate_payment_grin4u
      ? {
          heading: 'Clientes',
          id: 'clientes',
          items: [
            {
              id: 'clientes_all',
              children: 'Todos los clientes',
              onClick: () => {
                history.push('/clientes');
              },
            },
            user?.can?.executive_grin4u
              ? {
                  id: 'clientes_new',
                  children: 'Crear nuevo cliente',
                  onClick: () => {
                    history.push('/cliente/crear');
                  },
                }
              : null,
          ].filter((x) => !!x),
        }
      : null,
    user?.can?.executive_grin4u || user?.can?.validate_payment_grin4u
      ? {
          heading: 'Suscripciones',
          id: 'suscripciones',
          items: [
            {
              id: 'suscripciones_all',
              children: 'En proceso',
              onClick: () => {
                history.push('/suscripciones');
              },
            },
            {
              id: 'renewals',
              children: 'Renovaciones',
              onClick: () => {
                history.push('/renovaciones');
              },
            },
          ].filter((x) => !!x),
        }
      : null,
    user?.can?.analyst_grin4u ||
    user?.can?.executive_grin4u ||
    user?.can?.operations_grin4u
      ? {
          heading: 'Dashboard',
          id: 'dashboard',
          items: [
            user?.can?.analyst_grin4u
              ? {
                  id: 'dashboard_sales',
                  children: 'Dashboard de ventas',
                  onClick: () => {
                    history.push('/dashboard');
                  },
                }
              : null,
          ].filter((x) => !!x),
        }
      : null,
    user?.can?.executive_grin4u || user?.can?.operations_grin4u
      ? {
          heading: 'Rutas',
          id: 'rutas',
          items: [
            {
              id: 'rutas_all',
              children: 'Todas las rutas',
              onClick: () => {
                history.push('/rutas');
              },
            },
          ].filter((x) => !!x),
        }
      : null,
    user?.can?.manage_devices
      ? {
          heading: 'Patines',
          id: 'devices',
          items: [
            {
              id: 'fleet',
              children: 'Gestión de Flota',
              onClick: () => {
                history.push('/fleet');
              },
            },
            {
              id: 'devices_all',
              children: 'Todos los patines',
              onClick: () => {
                history.push('/devices');
              },
            },
            {
              id: 'devices_view',
              children: 'Ver patin',
              onClick: () => {
                history.push('/device');
              },
            },
          ].filter((x) => !!x),
        }
      : null,
  ].filter((x) => !!x);

  const closeMenuOnMobile = () => {
    if (isMobile()) {
      setIsMenuOpen(false);
    }
  };

  const onStartSearch = async (forceQ) => {
    const searchQ = (forceQ || q).replaceAll('\n', ',');
    if (!searchQ) {
      return;
    }
    const newUrl = `/clientes/?q=${encodeURIComponent(searchQ)}`;
    if (newUrl !== `${history.location.pathname}${history.location.search}`) {
      history.push(newUrl);
    }
  };
  const onCentrifugoMessage = async (channel, message) => {
    if (!message?.data) {
      return;
    }
    if (
      message.data.fleet_id &&
      selectedFleetRef &&
      selectedFleetRef.current &&
      parseInt(message.data.fleet_id, 10) !==
        parseInt(selectedFleetRef.current, 10)
    ) {
      return;
    }

    const {type} = message.data;
    if (type === 'notification') {
      Notifier.focus(
        message.data.notification.title,
        message.data.notification.message
      );
      notification.info({
        message: message.data.notification.title,
        placement: 'top',
        key: 'notif',
        onClick: () => {
          history.push(
            message.data.notification.url ||
              `/device/${message.data.notification.device?.qr}`
          );
          notification.destroy('notif');
        },
        duration: message.data.notification.duration || 3,
      });
    }
  };

  const onUpdate = async () => {
    const rsp = await getMe();
    if (rsp?.status) {
      setUser({user: rsp.data});
    }
  };

  const executeChangeWorkingStatus = async (status) => {
    setLoading(true);
    const rsp = await changeWorkingStatus(user.id, status);
    if (rsp?.status) {
      await onUpdate();
      history.push('/');
    }
    setLoading(false);
  };

  const getComponent = (matchProps) => {
    if (!selectedFleet) {
      return null;
    }
    let c = null;
    if (typeof component === 'function') {
      c = component(user);
    } else {
      c = component;
    }
    if (!c) {
      return null;
    }
    return (
      <>
        {React.createElement(c, {
          ...matchProps,
          user,
          tenant,
          fleets,
          selectedFleet: fleets?.find(
            (x) => x.id === parseInt(selectedFleet, 10)
          ),
          country: fleets?.find((x) => x.id === parseInt(selectedFleet, 10))
            ?.cost?.country,
          fleetId: parseInt(selectedFleet, 10),
          position,
          onUpdate,
        })}
      </>
    );
  };

  useEffect(() => {
    localStorage.setItem('__isSidebarOpen', isSidebarOpen);
  }, [isSidebarOpen]);

  useEffect(() => {
    localStorage.setItem('__position', JSON.stringify(position));
    if (
      user?.id &&
      (!lastNotifiedPosition ||
        (lastNotifiedPosition.latitude !== position.latitude &&
          lastNotifiedPosition.longitude !== position.longitude))
    ) {
      const dist = lastNotifiedPosition?.latitude
        ? distance(
            position.latitude,
            position.longitude,
            lastNotifiedPosition.latitude,
            lastNotifiedPosition.longitude
          ) * 1000
        : 100000;
      if (dist > 100) {
        setLastNotifiedPosition(position);
        setLastNotifiedPositionAt(new Date());
        const payload = {
          event: 'user:rental_location',
          item_type: 'user',
          user_id: user.id,
          item_id: user.id,
          lat: positionRef.current.latitude,
          lng: positionRef.current.longitude,
          dist,
          data: {
            dist,
          },
        };
        createEvent(payload);
      }
    }
  }, [position]);
  useEffect(async () => {
    setLoading(true);
    const [rspTenant, rspFleets] = await Promise.all([
      getTenantOfUser(),
      getAllFleets(),
    ]);
    if (rspTenant.status) {
      setTenant(rspTenant.tenant);
    }
    if (rspFleets.status) {
      setFleets(rspFleets.data);
    }
    setLoading(false);
    if (user) {
      const rsp = await getMe();
      if (rsp?.status) {
        setUser({user: rsp.data});
      }
    }
    document.title = `Rental${process.env.NODE_ENV !== 'production' ? ' - LOCAL' : ''}`;
  }, []);

  useEffect(() => {
    const url2 = `${document.location.pathname}${document.location.search}${document.location.hash}`;
    const payload2 = {
      event: 'user:view_url',
      item_type: 'user',
      user_id: user.id,
      item_id: user.id,
      lat: positionRef?.current?.latitude,
      lng: positionRef?.current?.longitude,
      data: {
        __v: pjson.version,
        e: 'load',
        url: url2,
      },
    };
    createEvent(payload2);
    const unlisten = history.listen((location) => {
      const url = `${location.pathname}${location.search}${location.hash}`;
      const payload = {
        event: 'user:view_url',
        item_type: 'user',
        user_id: user.id,
        item_id: user.id,
        lat: positionRef?.current?.latitude,
        lng: positionRef?.current?.longitude,
        data: {
          __v: pjson.version,
          e: 'navigate',
          url,
        },
      };
      createEvent(payload);
    });

    return () => {
      unlisten();
    };
  }, []);
  useEffect(() => {
    if (redirectTo) {
      if (typeof redirectTo === 'function') {
        history.push(redirectTo(user));
      } else {
        history.push(redirectTo);
      }
    }
  }, [redirectTo]);
  useEffect(() => {
    if (!selectedFleet && fleets && fleets.length > 0) {
      setSelectedFleet(fleets[0].id);
    }
  }, [fleets]);
  useEffect(() => {
    if (selectedFleet) {
      localStorage.setItem('__selected_fleet', selectedFleet);
    }
  }, [selectedFleet]);
  useEffect(() => {
    if (!user || !user.id || !user.can) {
      history.push('/logout');
    }
    if (user.track_work && user.working_status !== 'ACTIVE') {
      history.push('/usuario');
    }
    if (authorization) {
      setIsForbidden(!authorization(user));
    } else {
      setIsForbidden(false);
    }
  }, [user, authorization]);

  if (loading || !user || !tenant || !selectedFleet) {
    return (
      <div className={stylesLayout.page}>
        <Loading />
      </div>
    );
  }

  if (!component) {
    return null;
  }

  if (
    position &&
    position.error !== null &&
    !position.latitude &&
    !position.longitude
  ) {
    return (
      <div style={{padding: '200px 50px', fontSize: 18, textAlign: 'center'}}>
        <div style={{color: '#c30', fontSize: 24, marginBottom: 20}}>
          <FontAwesomeIcon icon={faTimesCircle} /> Error
        </div>
        <div>Para continuar debes habilitar la ubicación y luego recargar.</div>
      </div>
    );
  }
  if (!isOnline) {
    return (
      <div
        style={{
          color: '#c30',
          padding: '200px 50px',
          fontSize: 18,
          textAlign: 'center',
        }}
      >
        <div style={{fontSize: 24, marginBottom: 20}}>
          <img style={{width: 50}} src={noSignal} alt='' />
        </div>
        <div>No tienes conexión a internet</div>
      </div>
    );
  }

  const appAvailable = !user.track_work || user.working_status === 'ACTIVE'; // user.can?.use_rental_free;
  const hasFleetSelector = fleets?.length > 1;
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <ConfigProvider locale={locale}>
          <HotKeys keyMap={keyMap} handlers={keyHandlers}>
            <div className={styles.root}>
              <div
                className={styles.overlay}
                id='overlay'
                style={{display: 'none'}}
              />
              <div
                className={styles.scooterDetails}
                id='scooterDetails'
                style={{display: 'none'}}
              />
              <div className={styles.container}>
                <div
                  className={[
                    styles.sidebar,
                    isSidebarOpen ? styles.open : styles.close,
                    hasFleetSelector ? styles.hasFleetSelector : '',
                  ].join(' ')}
                  id='menubar'
                >
                  <div className={[styles.inner].join(' ')}>
                    {!isMobile() && (
                      <div
                        className={styles.closeButton}
                        onClick={() =>
                          setIsSidebarOpen(
                            (prevIsSidebarOpen) => !prevIsSidebarOpen
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={isSidebarOpen ? faChevronLeft : faChevronRight}
                        />
                      </div>
                    )}
                    <div className={styles.logo}>
                      <Link to='/'>
                        <img src={logo} alt='Rental' />
                      </Link>
                    </div>
                    <div className={styles.user}>
                      <div className={styles.name}>{user.email}</div>
                      <div className={styles.position}>
                        {position && position.latitude ? (
                          <>
                            <small>
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                color='#090'
                              />
                              {position.latitude}, {position.longitude}
                              <br />
                              {'Act: '}
                              {moment.utc(position.timestamp).fromNow()}
                              {user?.id === 1 && (
                                <>
                                  {'. Notif: '}
                                  {moment.utc(lastNotifiedPositionAt).fromNow()}
                                </>
                              )}
                            </small>
                            <br />
                            {!showLocationMap && (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a onClick={() => setShowLocationMap(true)}>
                                Ver mapa
                              </a>
                            )}
                            {showLocationMap && (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a onClick={() => setShowLocationMap(false)}>
                                Ocultar
                              </a>
                            )}
                            {showLocationMap && (
                              <div
                                className={styles.positionMap}
                                style={{height: 200}}
                              >
                                <LocationPicker
                                  location={{
                                    lat: position.latitude,
                                    lng: position.longitude,
                                  }}
                                  defaultZoom={15}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <FontAwesomeIcon
                            icon={faLocationArrow}
                            color='#c30'
                          />
                        )}
                      </div>
                      <div className={styles.logout}>
                        <Link to='/logout'>
                          <FontAwesomeIcon icon={faSignOutAlt} /> Salir
                        </Link>
                      </div>
                    </div>
                    {user.track_work && !isMobile() && (
                      <div className={styles.work}>
                        {user.working_status === 'NON_ACTIVE' && (
                          <Button
                            type='primary'
                            block
                            size='large'
                            onClick={() => {
                              executeChangeWorkingStatus('ACTIVE');
                            }}
                          >
                            Comenzar turno
                          </Button>
                        )}
                        {user.working_status === 'ACTIVE' && (
                          <>
                            <Button
                              type='primary'
                              block
                              danger
                              onClick={() => {
                                executeChangeWorkingStatus('NON_ACTIVE');
                              }}
                            >
                              Finalizar turno
                            </Button>
                            <div
                              style={{marginTop: 10, fontSize: 'var(--small)'}}
                            >
                              Activo desde:{' '}
                              {moment
                                .utc(user.data?.working_active_since)
                                .local()
                                .format('DD/MM HH:mm')}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {hasFleetSelector && !isMobile() && (
                      <div className={styles.fleets}>
                        <Select
                          value={parseInt(selectedFleet, 10)}
                          onChange={(id) =>
                            setSelectedFleet(fleets.find((x) => x.id === id).id)
                          }
                          style={{width: '100%'}}
                        >
                          {fleets &&
                            fleets.map((f) => (
                              <Option key={f.id} value={f.id}>
                                {f.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    )}

                    <div className={styles.menu}>
                      {isMobile() && (
                        <div className={styles.menubar}>
                          <div
                            className={styles.burger}
                            onClick={() => {
                              setShowLocationMap(false);
                              setShowCredential(false);
                              setIsMenuOpen((s) => !s);
                            }}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </div>
                          <div className={styles.fleets}>
                            {hasFleetSelector && (
                              <Select
                                value={parseInt(selectedFleet, 10)}
                                onChange={(id) =>
                                  setSelectedFleet(
                                    fleets.find((x) => x.id === id).id
                                  )
                                }
                                style={{width: '100%'}}
                              >
                                {fleets &&
                                  fleets.map((f) => (
                                    <Option key={f.id} value={f.id}>
                                      {f.name}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </div>
                          <div className={styles.locationIndicator}>
                            {position && position.latitude ? (
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                color='#090'
                                onClick={() => {
                                  setShowLocationMap(!showLocationMap);
                                  setShowCredential(false);
                                  setIsMenuOpen(false);
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                color='#c30'
                              />
                            )}
                          </div>
                          <div
                            className={styles.userInfo}
                            onClick={() => {
                              setShowCredential((s) => !s);
                              setShowLocationMap(false);
                              setIsMenuOpen(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faUser} color='#222' />
                          </div>
                          <div className={styles.version}>{pjson.version}</div>
                        </div>
                      )}
                      {isMenuOpen && (
                        <div
                          className={styles.options}
                          onClick={() => {
                            if (isMobile()) {
                              setIsMenuOpen(false);
                            }
                          }}
                        >
                          {user.track_work && isMobile() && (
                            <div className={styles.work}>
                              {user.working_status === 'NON_ACTIVE' && (
                                <Button
                                  type='primary'
                                  block
                                  size='large'
                                  onClick={() => {
                                    executeChangeWorkingStatus('ACTIVE');
                                  }}
                                >
                                  Comenzar turno
                                </Button>
                              )}
                              {user.working_status === 'ACTIVE' && (
                                <>
                                  <Button
                                    type='primary'
                                    block
                                    danger
                                    onClick={() => {
                                      executeChangeWorkingStatus('NON_ACTIVE');
                                    }}
                                  >
                                    Finalizar turno
                                  </Button>
                                  <div
                                    style={{
                                      marginTop: 10,
                                      fontSize: 'var(--small)',
                                    }}
                                  >
                                    Activo desde:{' '}
                                    {moment
                                      .utc(user.data?.working_active_since)
                                      .local()
                                      .format('DD/MM HH:mm')}
                                  </div>
                                </>
                              )}
                            </div>
                          )}

                          {appAvailable && (
                            <>
                              <Menu
                                style={{backgroundColor: 'transparent'}}
                                mode='inline'
                                items={menuItems.map((menuItem) => ({
                                  label: menuItem.heading,
                                  key: menuItem.id,
                                  type: 'group',
                                  children: menuItem.items.map((childItem) => ({
                                    label: childItem.children,
                                    key: childItem.id,
                                    onClick: childItem.onClick
                                      ? () => {
                                          childItem.onClick();
                                          closeMenuOnMobile();
                                        }
                                      : () => {},
                                  })),
                                }))}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isMobile() && (
                  <div
                    className={[
                      styles.topbar,
                      isSidebarOpen ? styles.open : styles.close,
                    ].join(' ')}
                  >
                    <div className={styles.input}>
                      <Input
                        value={q}
                        placeholder='Buscar cliente por email o teléfono...'
                        onChange={(e) => setQ(e.target.value)}
                        onKeyDown={(e) =>
                          e.key === 'Enter' ? onStartSearch() : null
                        }
                      />
                      <Button
                        type='primary'
                        onClick={() => onStartSearch()}
                        disabled={!q}
                      >
                        {loading ? 'Cargando' : 'Buscar'}
                      </Button>
                    </div>
                  </div>
                )}
                <div
                  className={[
                    styles.main,
                    isSidebarOpen ? styles.open : styles.close,
                    hasFleetSelector ? styles.hasFleetSelector : '',
                  ].join(' ')}
                >
                  <div
                    className={[
                      styles.inner,
                      fixedInner ? styles.innerFixed : null,
                    ].join(' ')}
                  >
                    {isForbidden ? (
                      <ForbiddenModule />
                    ) : (
                      getComponent(matchProps)
                    )}
                  </div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.inner}>
                    <div className={styles.version}>{pjson.version}</div>
                  </div>
                </div>
              </div>
              {isMobile() && showCredential && (
                <div className={styles.credential}>
                  <div className={styles.brand}>
                    <div className={styles.title}>
                      {user.is_internal ? (
                        <span>Autorizado</span>
                      ) : (
                        <span>Usuario</span>
                      )}
                    </div>
                    <img src='/grin-white.svg' alt='GRIN' />
                  </div>
                  <div className={styles.card}>
                    {user.data?.photo?.private_url && (
                      <div
                        className={styles.photo}
                        style={{
                          backgroundImage: `url(${user.data?.photo?.private_url})`,
                        }}
                      />
                    )}
                    <div className={styles.name}>{user.name}</div>
                    <div className={styles.email}>{user.email}</div>
                    <div className={styles.qr}>
                      <QRCode
                        value={`https://rental.grow.mobi/user_info/${user.hash}`}
                        size={110}
                        fgColor='#222'
                      />
                    </div>
                  </div>
                </div>
              )}
              {isMobile() && showLocationMap && position.latitude && (
                <div className={styles.positionMobile}>
                  <div className={styles.coords}>
                    {position.latitude}, {position.longitude}
                    <br />
                    {'Act: '}
                    {moment.utc(position.timestamp).fromNow()}
                    {user?.id === 1 && (
                      <>
                        {'. Notif: '}
                        {moment.utc(lastNotifiedPositionAt).fromNow()}
                      </>
                    )}
                  </div>
                  <div
                    className={styles.positionMap}
                    style={{height: window.innerHeight - 150}}
                  >
                    <LocationPicker
                      location={{
                        lat: position.latitude,
                        lng: position.longitude,
                      }}
                      defaultZoom={15}
                    />
                  </div>
                </div>
              )}
            </div>
          </HotKeys>
          {!isMobile() && <AppCommandPalette items={menuItems} />}
          {user && user.centrifugo_token && user.roles && (
            <>
              {user.roles.map((r) => (
                <CentrifugoSubscribeChannel
                  websocket='wss://centri.grow.mobi:9001/connection/websocket'
                  token={user.centrifugo_token}
                  channel={`role/${r.role}`}
                  onMessage={onCentrifugoMessage}
                  onConnect={(channel, c) => {
                    console.info('connect', channel, c);
                  }}
                  onError={(channel, c) => {
                    console.info('error', channel, c);
                  }}
                  onDisconnect={(channel, c) => {
                    console.info('disconnect', channel, c);
                  }}
                />
              ))}
            </>
          )}
        </ConfigProvider>
      )}
    />
  );
};

AdminLayout.defaultProps = {
  title: '',
  back: false,
  fixedInner: false,
  mqtt: null,
  user: {},
  redirectTo: null,
  authorization: () => true,
  requiredPermissions: null,
};

AdminLayout.propTypes = {
  title: PropTypes.string,
  mqtt: PropTypes.any,
  back: PropTypes.bool,
  user: PropTypes.object,
  redirectTo: PropTypes.string,
  path: PropTypes.any.isRequired,
  fixedInner: PropTypes.bool,
  authorization: PropTypes.func,
  requiredPermissions: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  component: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  title: state.layout && state.layout.get('title'),
  back: state.layout && state.layout.get('back'),
  user:
    state &&
    state.auth &&
    state.auth.get('user') &&
    state.auth.get('user').toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserAction(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
