export default (client, defaultParams) => ({
  remove: (id) => (
    client(`/gdrive/file/${id}`, {
      method: 'DELETE',
      params: {
        ...defaultParams,
      },
    })
  ),
  changeTitle: (id, title) => (
    client(`/gdrive/file/${id}/title/${title}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  uploadBase64: (data, extension, mimetype) => (
    client(`/upload/base64?extension=${extension}&mimetype=${mimetype}`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
    };
    return (messages[k] || k);
  },
});
