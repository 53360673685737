import bi from "./bi";
import toth from "./toth";
import tasks from "./tasks";
import users from "./users";
import horus from "./horus";
import trips from "./trips";
import notes from "./notes";
import leads from "./leads";
import files from "./files";
import routes from "./routes";
import ingest from "./ingest";
import grin4u from "./grin4u";
import events from "./events";
import fleets from "./fleets";
import tenants from "./tenants";
import devices from "./devices";
import mqttLog from "./mqtt_log";
import geofences from "./geofences";
import incidents from "./incidents";
import deployPlans from "./deployPlans";
import subscriptions from "./subscriptions";

export default {
  bi,
  toth,
  tasks,
  users,
  horus,
  trips,
  notes,
  leads,
  files,
  routes,
  ingest,
  grin4u,
  events,
  fleets,
  tenants,
  devices,
  mqttLog,
  geofences,
  incidents,
  deployPlans,
  subscriptions,
};
