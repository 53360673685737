/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Button, Input, Space, Checkbox, DatePicker,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as notesService from '../../services/notes';

import stylesLayout from '../../common/layout.module.scss';

import styles from './index.module.scss';
import Loading from '../../components/Loading';

dayjs.extend(utc);

const NotesListModule = ({
  // eslint-disable-next-line no-unused-vars
  user,
  itemType,
  itemId,
}) => {
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const refNote = useRef(null);
  const [createReminder, setCreateReminder] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);

  const loadNotes = async () => {
    setLoading(true);
    const rsp = await notesService.getForItem(itemType, itemId);
    if (rsp?.status) {
      setNotes(rsp.data);
    }
    setLoading(false);
  };
  const createNote = async () => {
    if (!refNote?.current?.resizableTextArea.textArea.value) {
      return;
    }
    setSaving(true);
    const rsp = await notesService.create({
      user_id: user.id,
      item_type: itemType,
      item_id: itemId,
      remind_at: (createReminder && reminderDate) ? dayjs(reminderDate).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      note: refNote?.current?.resizableTextArea.textArea.value,
    });
    if (rsp?.status) {
      setNotes((prevNotes) => [rsp.data, ...prevNotes]);
    }
    setSaving(false);
  };
  useEffect(() => {
    loadNotes();
  }, []);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading size="sm" />
        </div>
      </div>
    );
  }

  return (

    <div className={styles.notes}>
      <Space direction="vertical" size={10} style={{ width: '100%', marginBottom: 20 }}>
        <Input.TextArea
          ref={refNote}
          placeholder="Nueva nota..."
          disabled={saving}
          width="100%"
          style={{ width: '100%', resize: 'none' }}
        />
        <div>
          <Checkbox
            defaultValue={createReminder}
            onChange={(e) => { setCreateReminder(e.target.checked); }}
          >
            Crear recordatorio
          </Checkbox>
        </div>

        {createReminder && (
          <div>
            <DatePicker
              style={{ width: '100%' }}
              onChange={(v) => setReminderDate(v)}
              format="YYYY-MM-DD"
            />
          </div>
        )}
        <Button onClick={() => createNote()} disabled={saving}>
          {saving
            ? <><FontAwesomeIcon icon={faSpinner} spin /></>
            : (
              <>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                Agregar
              </>
            )}
        </Button>
      </Space>
      {
        (!notes || notes.length === 0)
          ? <div className={styles.noNotes}>No hay notas</div>
          : (
            <>
              <div className={styles.list}>
                {notes.map((e) => (
                  <div className={styles.note}>
                    <div className={styles.date}>
                      <div className={styles.text}>
                        {e.note}
                      </div>
                      {e.user?.name || 'Sin nombre'}
                      {' '}
                      -
                      {' '}
                      {e.user?.email}
                      <div>
                        Creada:
                        {' '}
                        {moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                      {e.remind_at && (
                        <div>
                          <FontAwesomeIcon icon={faBell} style={{ marginRight: 5, color: 'var(--mainColor)' }} />
                          Recordatorio:
                          {' '}
                          {moment.utc(e.remind_at).local().format('YYYY-MM-DD')}
                          {' '}
                          (
                          {moment.utc(e.remind_at).fromNow()}
                          )
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
      }
    </div>
  );
};
NotesListModule.defaultProps = {
};

NotesListModule.propTypes = {
  user: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default NotesListModule;
