/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
} from 'antd';
import { API_BASE_URL } from '../../environment';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const UploaderFile = ({
  className,
  maxSizeMB,
  onStartUpload,
  onUpload,
  uploadUrl,
  render,
  fileUrl,
  onError,
  accept,
  label,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(fileUrl);

  const beforeUpload = (file) => {
    const isAllowedType = !accept || accept.split(',').includes(file.type);
    const errors = [];
    if (!isAllowedType) {
      errors.push('El archivo debe ser de un tipo permitido');
    }
    const isSmall = file.size / 1024 / 1024 < maxSizeMB;
    if (!isSmall) {
      errors.push(`El tamaño máximo del archivo debe ser ${maxSizeMB}MB`);
    }
    if (errors && errors.length && errors.length > 0) {
      if (onError) {
        onError(errors);
      } else {
        // eslint-disable-next-line no-alert
        alert(errors.join('\n'));
      }
    }
    return errors.length === 0;
  };

  const handleChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      onStartUpload();
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (rsp) => {
        setUploadedFileUrl(rsp);
        setUploaded(true);
        setUploading(false);
        if (onUpload) {
          onUpload({
            file: info.file.response,
            filename: info.file.response.url,
            uploadedFileUrl,
          });
        }
      });
    }
  };

  return (
    <Upload
      name="file"
      listType=""
      accept={accept}
      className={className}
      showUploadList={false}
      action={uploadUrl || `${API_BASE_URL}/upload`}
      beforeUpload={beforeUpload}
      onChange={(info) => handleChangeUpload(info)}
    >
      {render({ label, uploading, uploaded })}
    </Upload>
  );
};

UploaderFile.defaultProps = {
  className: '',
  label: 'Subir',
  fileUrl: null,
  uploadUrl: null,
  maxSizeMB: 10,
  onStartUpload: () => { },
  onUpload: () => { },
  onError: null,
  render: () => { },
  accept: 'image/png,image/jpeg,application/pdf',
};
UploaderFile.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.string,
  label: PropTypes.string,
  uploadUrl: PropTypes.string,
  fileUrl: PropTypes.string,
  maxSizeMB: PropTypes.number,
  onUpload: PropTypes.func,
  render: PropTypes.func,
  onStartUpload: PropTypes.func,
  onError: PropTypes.func,
};
export default UploaderFile;
