/* eslint-disable max-len */
import apiClient from '../api';
import { executeAndWaitResponse } from './service';

export const create = async (payload) => executeAndWaitResponse(() => apiClient.routes.create(payload));
export const update = async (id, payload) => executeAndWaitResponse(() => apiClient.routes.update(id, payload));
export const get = async (id) => executeAndWaitResponse(() => apiClient.routes.get(id));
export const getToday = async (fleetId) => executeAndWaitResponse(() => apiClient.routes.getToday(fleetId));
export const getForFleet = async (fleetId) => executeAndWaitResponse(() => apiClient.routes.getForFleet(fleetId));
export const getForFleetDate = async (fleetId, date) => executeAndWaitResponse(() => apiClient.routes.getForFleetDate(fleetId, date));

export default {
  create,
  update,
  get,
  getToday,
  getForFleet,
  getForFleetDate,
};
