import * as deviceData from 'mobile-device-detect';

export default (client, defaultParams) => ({
  create: (payload) => (
    client('/note', {
      method: 'POST',
      data: {
        ...payload,
        _d: deviceData,
      },
      params: {
        ...defaultParams,
      },
    })
  ),
  getForItem: (itemType, itemId) => (
    client(`/admin/notes/${itemType}/${itemId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
});
