import React, {useEffect} from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import {Connector} from 'mqtt-react-hooks';

import isMobile from 'is-mobile';
import BlankLayout from './layouts/BlankLayout';
import AdminLayout from './layouts/AdminLayout';

import CustomerModule from './modules/CustomerModule';
import LoginModule from './modules/LoginModule';
import LogoutModule from './modules/LogoutModule';
import FleetManagementModule from './modules/FleetManagementModule';
import HomeModule from './modules/HomeModule';
import LeadsModule from './modules/LeadsModule';
import CustomersModule from './modules/CustomersModule';
import SubscriptionsModule from './modules/SubscriptionsModule';
import RoutesModule from './modules/RoutesModule';
import RouteModule from './modules/RouteModule';
import SubscriptionModule from './modules/SubscriptionModule';
import RegisterModule from './modules/RegisterModule';
import DevicesModule from './modules/DevicesModule';
import NewDeviceModule from './modules/NewDeviceModule';
import DeviceModule from './modules/DeviceModule';
import MobileDeviceModule from './modules/MobileDeviceModule';
import DashboardModule from './modules/DashboardModule';
import AnalysisLeadsModule from './modules/AnalysisLeadsModule';
import RenewalsModule from './modules/RenewalsModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1)); // id=123
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {}
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
  }, []);
  return (
    <Connector
      brokerUrl='mqtt://broker.grow.mobi:9001'
      options={{
        protocol: 'wss',
        username: 'growdevice',
        password: '4F7C84871LQD',
      }}
    >
      <BrowserRouter>
        <Switch>
          <BlankLayout exact path='/login' component={LoginModule} />
          <BlankLayout exact path='/logout' component={LogoutModule} />
          <AdminLayout
            exact
            path='/'
            redirectTo={(user) => {
              if (
                user?.can?.executive_grin4u ||
                user?.can?.operations_grin4u ||
                user?.can?.validate_payment_grin4u
              ) {
                return '/suscripciones';
              }
              return '/ruta';
            }}
          />
          <AdminLayout exact path='/inicio' component={() => HomeModule} />
          <AdminLayout
            exact
            path='/fleet'
            component={() => FleetManagementModule}
            authorization={(user) => user?.can?.manage_fleet}
          />
          <AdminLayout
            exact
            path='/clientes'
            component={() => CustomersModule}
            authorization={(user) =>
              user?.can?.executive_grin4u || user?.can?.validate_payment_grin4u
            }
          />
          <AdminLayout
            exact
            path='/renovaciones'
            component={() => RenewalsModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u
            }
          />
          <AdminLayout
            exact
            path='/suscripciones'
            component={() => SubscriptionsModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u
            }
          />
          <AdminLayout
            exact
            path='/leads'
            component={() => LeadsModule}
            authorization={(user) => user?.can?.executive_grin4u}
          />
          <AdminLayout
            exact
            path='/analysis'
            component={() => AnalysisLeadsModule}
            authorization={(user) => user?.can?.executive_grin4u}
          />
          <AdminLayout
            exact
            path='/dashboard'
            component={() => DashboardModule}
            authorization={(user) => user?.can?.analyst_grin4u}
          />
          <AdminLayout
            exact
            path='/rutas'
            component={() => RoutesModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u
            }
          />
          <AdminLayout
            exact
            path='/ruta'
            component={() => RouteModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u ||
              user?.can?.transportist
            }
          />
          <AdminLayout
            exact
            path='/ruta/:id'
            component={() => RouteModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u ||
              user?.can?.transportist
            }
          />
          <AdminLayout
            exact
            path='/suscripcion/:id'
            component={() => SubscriptionModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u ||
              user?.can?.validate_payment_grin4u
            }
          />
          <AdminLayout
            exact
            path='/cliente/crear'
            component={() => RegisterModule}
            authorization={(user) => user?.can?.executive_grin4u}
          />
          <AdminLayout
            exact
            path='/cliente/:id'
            component={() => CustomerModule}
            authorization={(user) =>
              user?.can?.executive_grin4u ||
              user?.can?.validate_payment_grin4u ||
              user?.can?.operations_grin4u ||
              user?.can?.validate_payment_grin4u
            }
          />

          <AdminLayout
            exact
            path='/create_device'
            component={() => NewDeviceModule}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout
            exact
            path='/devices'
            component={() => DevicesModule}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout
            exact
            path='/devices/:refs'
            component={() => DevicesModule}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout
            exact
            path='/device'
            component={() => (isMobile() ? MobileDeviceModule : DeviceModule)}
            fixedInner={isMobile()}
            authorization={(user) => user?.can?.manage_devices}
          />
          <AdminLayout
            exact
            path='/device/:q'
            component={() => (isMobile() ? MobileDeviceModule : DeviceModule)}
            fixedInner={isMobile()}
            authorization={(user) => user?.can?.manage_devices}
          />
        </Switch>
      </BrowserRouter>
    </Connector>
  );
};

Routes.defaultProps = {};

Routes.propTypes = {};

export default Routes;
